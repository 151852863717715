@import "~antd/dist/antd.css";

p {
	margin: 0;
}

#root {
	font-size: 100%;
	min-height: 100vh;
	height: 100%;
	@media screen and (max-width: 1180px) {
		font-size: 80%;
	}
	@media screen and (max-width: 1024px) {
		font-size: 70%;
	}
	@media screen and (max-width: 768px) {
		font-size: 14px;
	}
	@media screen and (max-width: 400px) {
		font-size: 13px;
	}
}

.ant-message {
	font-size: 1em;
	top: 10%;
	transform: translateY(-50%);
	@media screen and (max-width: 768px) {
		top: 20%;
		transform: translateY(-50%);
	}
}

.ant-table {
	font-size: 1em;
}

.ant-table-empty {
	z-index: 1;
}

.ant-image-preview-mask {
	background-color: rgba(0, 0, 0, 0.85);
}

.max-width-apply {
	max-width: 1200px;
	margin: auto !important;
	width: 100%;
}

#landing-width {
	max-width: 100% !important;
}

/** text 드래그 금지 **/
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.together-header-wrap,
.together-footer-wrap {
	max-width: 1200px !important;
	margin: auto;
}

.main-wrap {
	max-width: 1200px !important;
	margin: auto;

	&.event-banner-page {
		padding: 0;
		margin-left: 0;
		margin-right: 0;
		max-width: none !important;
	}
}

.landing-home {
	margin-top: 5em;

	@media (max-width: 768px) {
		.together-text-div {
			width: 60%;
			top: 50%;
			gap: 1.5em;

			.ball-arrow-img {
				padding-top: 3em !important;
			}
		}
		.together-wrap {
			padding: 4em 1.5em !important;

			> div {
				width: 100% !important;
			}

			.together-div {
				flex-direction: column;

				.together-column {
					width: 100%;

					.together-img-2 {
						padding-top: 3rem !important;
					}

					.blue-title {
						padding: 0;
					}

					.big-title {
						font-size: 1.8em;
						padding-bottom: 1em;
					}

					.small-title {
						font-size: 1.1em;
						padding-bottom: 1em;
					}

					&.together-profit {
						.small-title {
							font-size: 1.5em !important;
							padding-bottom: 1em;
						}
					}
				}

				.together-group {
					flex-direction: column !important;
					align-items: center !important;
					justify-content: flex-start !important;
					gap: 0 !important;

					.together-each {
						width: 90%;

						img {
							border-radius: 20px !important;
						}
					}
				}
			}

			.together-apply-group {
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				gap: 1.5em;

				img {
					width: 90% !important;
				}
			}
		}
	}
}

.together-profit {
	.small-title {
		font-size: 1.8em;
		font-weight: bold;
	}
}
.user-grade-icon-nickname {
	display: none;
}
.user-grade-icon-nickname.icon {
	display: flex;
	@media (max-width: 768px) {
		display: flex;
	}
}
@media (max-width: 768px) {
	.header {
		padding: 1em 1.5em !important;
		overflow: hidden;
		height: 5em !important;
		&.sky {
			background-color: #f3f9ff;
		}

		&.hidden {
			display: none;
		}
	}
	.header-right-mobile {
		display: none !important;
	}
	.main-wrap {
		margin-top: 5em !important;
		padding: 2em 1.2em !important;
		overflow: hidden;
		&.benefit-information {
			padding-top: 0 !important;
		}

		&.dashboard {
			background: rgb(243, 249, 255);
			padding: 0 0 1.2em !important;
		}

		&.gold-settlement-request-complete-wrap {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: calc(100vh - 5em);

			.confirm {
				background-color: #117ffa;
				border-radius: 5px;
				padding: 16px 0;
				border: none;
				height: auto;

				span {
					font-size: 1.2em;
					font-weight: 600;
					line-height: 1.2;
					color: #ffffff;
				}
			}
		}

		&.notice {
			padding-bottom: 0 !important;
		}

		&.padding-0 {
			padding: 0 !important;
		}

		&.event-banner-page {
			padding: 0 !important;
		}
	}
	.footer-information {
		align-items: flex-start !important;
	}
	.footer-address-text {
		display: none;
	}
	.footer-address-text-mobile {
		white-space: pre-wrap;
	}
	.footer {
		padding: 3em 1.5em !important;
		overflow: hidden;

		.footer-information {
			flex-direction: column;

			div {
				flex-direction: row !important;
				gap: 3px;
				align-items: center;
				width: 100%;
				padding-top: 0.5em;
			}
		}

		.footer-phone-email-wrap {
			display: flex;
			flex-direction: column;

			span {
				margin: 0 !important;
			}
		}

		a {
			text-align: right;
		}

		.footer-inquiry-wrap {
			display: flex;
			flex-direction: column;

			span.division {
				margin: 0 !important;
				display: none;
			}
		}
	}

	.together-content-login-img {
		display: none;
	}
	.together-content-login-col {
		max-width: 100%;
		flex: 1;
	}
}

.footer-address-text-mobile {
	display: none;
	@media screen and (max-width: 768px) {
		display: block;
	}
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Header - 헤더 ]                                                 *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/
.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	height: 5em;
	z-index: 99;
	//padding: 0 10em;
}

.header .together-header-wrap {
	height: 100%;
	padding: 0.5em 3em;

	@media (max-width: 768px) {
		padding: 0.5em 0;
	}

	&.channel-header-wrap {
		padding: 0.5em 0;
	}
}

.header .together-header-wrap .together-header-logo {
	width: auto;
	height: 1.4em;
}

.header-link-div {
	display: flex;
	align-items: center;
	gap: 1em;

	a {
		font-size: 1em;
		color: #212529;
		text-align: center;
	}

	.link-group {
		display: flex;
		gap: 1.5em;
		padding-left: 2em;
		align-items: center;
		width: 100%;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.menu-dropdown {
		display: none;
		@media screen and (max-width: 768px) {
			display: flex;
		}
	}

	.login-button {
		cursor: pointer;
		border: solid 1px #ced4da;
		padding: 2px 10px;
		border-radius: 3px;
		margin-left: auto;
		margin-bottom: 0;
		font-size: 0.9em;
		width: fit-content;
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (max-width: 768px) {
			//display: none;
			padding: 2px 6px;
			font-size: 0.9em;
			height: fit-content;
		}
	}
}

// 모달 모바일 버전
.ant-modal {
	@media screen and (max-width: 768px) {
		width: 100% !important;
		div.ant-modal-content {
			overflow: auto;
		}
		div.ant-modal-content {
			overflow: auto;
		}
	}
}

.ant-pagination-item-link {
	svg {
		width: 0.6em;
		height: auto;
	}
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Footer - 푸터 ]                                                 *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/
.footer {
	padding: 4em 10em;
	background-color: rgba(200, 226, 255, 0.1);
	font-size: 1em;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	text-align: left;
	color: #ffffff;

	a,
	div,
	span {
		color: #2a2a2a !important;
		font-weight: 300;
	}

	.together-footer-wrap {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		.footer-dangyoung-logo {
			width: 8.5em;
			height: auto;
			margin-bottom: 10px;
		}

		.footer-information {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			a {
				text-decoration: underline;
				text-align: right;
			}
		}

		.footer-phone-email-wrap {
			margin-top: 12px;
			white-space: pre;
		}

		.footer-inquiry-wrap {
			margin-top: 15px;
			white-space: pre;
			display: flex;

			span,
			a {
				width: 35%;
			}

			span.division {
				width: 5%;
				text-align: left;
			}
		}
	}
}

.inquiry-guide-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
	width: 100%;
	gap: 3%;
	background-color: #ffffff;

	@media screen and (max-width: 768px) {
		padding: 0;
		gap: 0;
	}

	.item-wrap {
		display: flex;
		align-items: center;
		width: 50%;
		gap: 1.5rem;
		cursor: pointer;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			width: 100%;
			max-width: 300px;
			gap: 0;
			padding: 0 5% 20px;
		}
	}

	.inquiry-guide-item-divider {
		background-color: #eeeeee;
		width: 1px;
		height: 114px;

		@media screen and (max-width: 768px) {
			height: 150px;
		}
	}

	.img-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 11rem;

		@media screen and (max-width: 768px) {
			width: 80%;
			height: auto;
		}
	}

	.text-wrap {
		width: auto;

		@media screen and (max-width: 768px) {
			width: 100%;
			text-align: center;
		}
	}
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Content - 콘텐츠 ]                                                 *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/
.content {
	background-color: #ffffff;
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Landing - 랜딩페이지 ]                                                 *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/
.together-landing-header-bgImg-wrap {
	position: relative;
}

.together-text-div {
	position: absolute;
	width: 50%;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3em;

	&.backup {
		width: 18%;

		@media screen and (max-width: 768px) {
			width: 50%;
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	a {
		width: 100%;
		height: auto;
		max-width: 10em;
	}

	.together-landing-title-div {
		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			font-size: 3em;
			font-weight: bold;
			line-height: 1.5;
			letter-spacing: -1px;
			margin: 0;
			color: white;
		}

		p {
			font-size: 1.2em;
			line-height: 1.44;
			letter-spacing: -1px;
			margin: 0;
			color: white;
		}
	}
}

.together-wrap {
	width: 100%;
	justify-content: center;
	flex-direction: row !important;
	margin: 0 !important;
	padding: 6em 10em !important;

	@media (min-width: 1440px) {
		font-size: 140%;
	}

	@media (min-width: 1520px) {
		padding: 8em 17em !important;
		font-size: 22px;
	}
}

.together-header-height {
	height: 5em;
}

.together-header {
	@media (min-width: 1440px) {
		padding: 0 13em !important;
	}

	@media (min-width: 1920px) {
		padding: 0 20em !important;
	}
}

.together-div {
	width: 100%;
	display: flex;
}

.together-column {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.together-group {
	display: flex;
	width: 100%;
	justify-content: center;
	padding-bottom: 5rem;
	gap: 10%;
}

.together-each {
	display: flex;
	width: 40%;
	flex-direction: column;
}

.together-apply-group {
	display: flex;
	width: 100%;
	gap: 2%;
}

.blue-title {
	margin: 0;
	color: #117ffa;
	font-size: 1.3em;
	font-weight: 600;
	white-space: pre-wrap;
	padding-bottom: 0.5em;
}

.big-title {
	margin: 0;
	color: #212529;
	font-size: 2.3em;
	font-weight: 700;
	white-space: pre-wrap;
	line-height: 1.4;
	padding-bottom: 1.8em;
}

.small-title {
	margin: 0;
	color: #343a40;
	font-size: 1.3em;
	font-weight: 600;
	white-space: pre-wrap;
	padding-bottom: 0.5em;
}

.ball-arrow-img {
	position: absolute;
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Login - 로그인 화면 ]                                                 *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/
.login-content {
	min-height: calc(100vh - 5em);
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;

	margin-top: 5em;
	padding: 4em 3em;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	gap: 5%;
	align-items: center;
	@media screen and (max-width: 768px) {
		height: auto;
		padding: 4em 1.5em;
		margin-top: 5em !important;
		flex-direction: column-reverse;
		row-gap: 3em !important;
		> div {
			width: 100%;
		}
		.together-content-login-form-logo {
			width: 70% !important;
			max-width: 14em;
		}
		.together-login-form-help-text {
			width: 100%;
			margin: 2.5em 0 !important;
		}
		.ant-form-item {
			width: 100% !important;
			margin-bottom: 1em;
		}
	}
}

.login-content .together-content-login-img {
	border-radius: 15px;
	width: 100%;
	height: 100%;
}

.login-content .together-content-login-form-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
}

.login-content
	.together-content-login-form-wrap
	.together-content-login-form-logo {
	width: 40%;
	height: auto;
}

.login-content
	.together-content-login-form-wrap
	.together-login-form-help-text {
	font-family: NotoSansCJKKR;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	text-align: center;
	color: #6d7580;
	white-space: pre-line;
	margin-top: 46px;
	margin-bottom: 11px;
}

.login-content
	.together-content-login-form-wrap
	.together-login-form-help-text
	.together-login-form-help-text-bold {
	font-weight: bold;
	color: #212529;
}

.login-content
	.together-content-login-form-wrap
	.ant-form-item
	.ant-form-item-label
	label {
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	text-align: left;
	color: #6d7580;
}

.login-content
	.together-content-login-form-wrap
	.ant-form-item
	.ant-form-item-control
	.ant-form-item-control-input {
	width: 100%;
}

.login-content .together-content-login-form-wrap .together-login-id-input,
.login-content
	.together-content-login-form-wrap
	.together-login-password-input {
	min-height: 50px;
}

.login-content
	.together-content-login-form-wrap
	.ant-form-item-control
	.ant-form-item-control-input-content
	.ant-input {
	font-size: 16px;
}

.login-content .together-content-login-form-wrap .together-login-button {
	padding-top: 15px;
	padding-bottom: 13px;
	width: 100%;
	height: fit-content;
	border-radius: 5px;
	background-color: #117ffa;

	span {
		font-size: 1.2em;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		text-align: center;
		color: #ffffff;
	}
}

.login-content .together-content-login-form-wrap .together-login-button-wrap {
	margin-bottom: 0;
	margin-top: 14px;
}

.login-content
	.together-content-login-form-wrap
	.together-login-button-wrap
	.together-login-button[disalbed="disabled"],
.login-content
	.together-content-login-form-wrap
	.together-login-button-wrap
	.together-login-button:disabled {
	background-color: #adb5bd;
	color: #ffffff;
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Report - 리포트 ]                                                 *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/

.report-date-search-wrap {
	width: 100%;
}

.report-date-search-wrap .ant-collapse-item .ant-collapse-header {
	padding-left: 0;
	@media (max-width: 768px) {
		padding: 0;
	}
}
.ant-collapse-borderless .ant-collapse-item .ant-collapse-content {
	@media (max-width: 768px) {
		background: #f6f6f6 !important;
	}
}
.report-date-search-wrap .ant-collapse-item .ant-collapse-content {
	border-radius: 0;
}

.report-date-search-wrap
	.ant-collapse-item
	.ant-collapse-content
	.ant-collapse-content-box {
	border-radius: 10px;
	background-color: #f8f9fa;
	padding: 2em;
	margin-bottom: 1.5em;

	@media (max-width: 768px) {
		padding: 1.2em 0;
		margin-bottom: 1.2em;
		border-top: none !important;
		background-color: #f6f6f6;
	}
}

.report-date-search-wrap .report-date-search-content-wrap {
	display: flex;
	flex-direction: column;
	// align-items: center;
	gap: 1.5em;

	@media (max-width: 768px) {
		padding: 0 1.2em;
		gap: 1em;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-title {
	font-size: 14px;
	font-weight: 500;
	color: #1a1a1a;
	padding: 0 3em;
	margin-right: 1em;

	@media (max-width: 768px) {
		padding: 0;
		margin-right: 0;
		font-size: 1em;
		line-height: 1.25;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-select-type-wrap {
	@media (max-width: 768px) {
		width: 100%;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-select-type-wrap
	.ant-radio-button-wrapper {
	margin-left: 2px;
	margin-right: 2px;
	text-align: center;

	@media (max-width: 768px) {
		margin-left: 0;
		margin-right: 0;
		padding: 4px 0;
		width: 20%;
		height: auto;
	}

	span {
		font-weight: 500;
		font-size: 1em;
		line-height: 1.25;
		color: #9d9996;
	}

	&.ant-radio-button-wrapper-checked {
		border-color: #117ffa;

		span {
			color: #117ffa;
			background-color: #eef6ff;
			font-weight: 600;
			font-size: 1em;
		}
	}
}
.report-date-search-wrap {
	.report-date-search-content-wrap {
		.report-date-search-select-type-wrap {
			&.report-date-search-select-type-wrap-group {
				padding-left: 2px;

				@media (max-width: 768px) {
					padding-left: 0;
					width: 100%;
				}

				.ant-radio-button-wrapper {
					padding: 0 4em;
					margin: 0;

					@media (max-width: 768px) {
						padding: 6px 0;
						width: calc(100% / 3);
						height: auto;
					}
				}
			}
		}
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-search-period-wrap {
	display: flex;
	align-items: center;

	@media (max-width: 768px) {
		width: 100%;
		gap: 0.8em;
	}

	.report-search-period-text {
		font-weight: 500;
		font-size: 1.45em;
		line-height: 1.2;
		color: #9d9996;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-search-date-wrap {
	margin-left: 10px;
	margin-right: 10px;
	min-width: 10em;
	width: 20%;

	@media (max-width: 768px) {
		margin: 0;
		flex: 1;
		width: auto;
		min-width: auto;
		padding: 8px 0.8em;
	}

	.ant-picker-input > input {
		color: #9d9996;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-button-wrap {
	@media (max-width: 768px) {
		width: 100%;
		justify-content: stretch;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-button-wrap
	.report-date-search-button {
	width: 7em;
	font-size: 1em;
	height: fit-content;
	margin-left: 5px;
	margin-right: 5px;

	@media (max-width: 768px) {
		width: 100%;
		border-radius: 5px;
		margin-left: 0;
		margin-right: 0;
		padding: 1em 0;
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-button-wrap
	.report-date-search-button.confirm {
	background-color: #117ffa;

	span {
		color: #ffffff;

		@media (max-width: 768px) {
			font-weight: 600;
			font-size: 1.2em;
			line-height: 1.2;
		}
	}
}

.report-date-search-wrap
	.report-date-search-content-wrap
	.report-date-search-button-wrap
	.report-date-search-button.cancel {
	border: solid 1px #ced4da;
	background-color: #ffffff;

	span {
		color: #495057;
	}
}

.report-statistics-wrap {
	border-radius: 10px;
	border: solid 1px #ced4da;
	background-color: #ffffff;
	width: 100%;
	padding: 2em;
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		row-gap: 1em !important;

		div {
			//flex-grow: 1;
			//min-width: 50%;
			text-align: center;
		}
	}
}

.report-statistics-item-wrap {
	font-size: 1em;
	font-weight: 500;
	color: #1a1a1a;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.3em;
	width: 25%;

	@media screen and (max-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		color: #1a1a1a;
		font-size: 1.2em;
		line-height: 1.2;
		gap: 0;
	}
}

.report-statistics-item-wrap .report-statistics-value-wrap {
	font-size: 1.3em;
	color: #212529;
	letter-spacing: -0.6px;

	@media screen and (max-width: 768px) {
		color: #1a1a1a;
		font-weight: 500;
		letter-spacing: initial;
		font-size: 1.2em;
		line-height: 1.2;
	}

	.report-statistics-value-unit {
		color: #1a1a1a;
		font-weight: 500;
		font-size: 0.65em;
		margin-left: 3px;
	}
}

.report-table-wrap {
	margin-top: 2.5em;
	width: 100%;
	span.blue {
		color: #117ffa;
	}
}

.report-table-wrap .ant-table {
	border-radius: 0;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	text-align: left;
	color: #343a40;

	@media screen and (max-width: 768px) {
		color: #1a1a1a;
	}
}

.report-table-wrap .ant-table .ant-table-container {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.report-table-wrap .ant-table table {
	border-radius: 0;
}

.report-table-wrap .ant-table .ant-table-content .ant-table-thead tr th {
	background-color: #ffffff;
	border-bottom: solid 1px #212529;
	font-weight: bold;
	text-align: center;

	@media screen and (max-width: 768px) {
		border-top: solid 1px #1a1a1a;
		border-bottom: solid 1px #1a1a1a;
		color: #1a1a1a;
		font-weight: 600;
		line-height: 1.22;
	}
}

.report-table-wrap
	.ant-table.ant-table-empty
	.ant-table-content
	.ant-table-thead
	tr
	th {
	border-bottom: solid 1px #1a1a1a;
}

.report-table-wrap
	.ant-table
	.ant-table-container
	table
	thead
	tr:first-child
	th:first-child {
	border-top-left-radius: 0;
}

.report-table-wrap
	.ant-table
	.ant-table-content
	.ant-table-thead
	tr
	th:not(:last-child):not(.ant-table-selection-column):not(
		.ant-table-row-expand-icon-cell
	):not([colspan])::before {
	display: none;
}

.report-table-wrap .ant-table .ant-table-content .ant-table-tbody tr td {
	text-align: center;
}

.report-table-wrap .ant-table .ant-table-footer {
	padding: 0;
	border-top: solid 1px #212529;
}

.report-table-wrap .ant-table.ant-table-empty .ant-table-footer {
	border-top: solid 1px #1a1a1a;
}

.report-pagination-wrap {
	margin-top: 3em;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.21;
	text-align: center;
	color: #868e96;
	display: flex;
	justify-content: center;
	align-items: center;

	li {
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;

		.ant-pagination-item-link {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		a {
			line-height: 1;
		}
	}
}

.report-pagination-wrap .ant-pagination-prev .ant-pagination-item-link,
.report-pagination-wrap .ant-pagination-next .ant-pagination-item-link {
	border: 0;
	border-radius: 0;
	font-size: 24px;
}

.report-pagination-wrap .ant-pagination-item {
	border: 0;
	border-radius: 0;
	font-size: 1.2em;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
}

.report-pagination-wrap .ant-pagination-item a {
	color: #868e96;
	font-weight: 700;
}

.report-pagination-wrap .ant-pagination-item.ant-pagination-item-active {
	background-color: #000;
	border-radius: 50%;
}

.report-pagination-wrap .ant-pagination-item.ant-pagination-item-active a {
	color: #ffffff;
}

.report-pagination-wrap
	.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-link-icon,
.report-pagination-wrap
	.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-link-icon {
	font-size: 24px;
}

.together-content-login-col {
	padding: 0;
	width: 45%;
	flex-grow: 1;
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ main - 메인 ]                                                   *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/

.main-wrap {
	padding: 3em;
	display: flex;
	flex-direction: column;
	margin-top: 5em;
}

.link-wrap {
	display: flex;
	align-items: center;
	gap: 0.5em;
	font-size: 0.9em;
	padding-bottom: 32px;
	font-weight: 500;
	line-height: 17px;
	@media (max-width: 768px) {
		display: none;
	}

	span {
		color: #d9d9d9;
	}

	p {
		color: #d9d9d9;
		margin: 0;
	}
}

.main-nav {
	width: 100%;
	// border-bottom: solid 1px #dee2e6;
	display: flex;
	margin-bottom: 0.5em;
	overflow: auto;

	button {
		padding: 0.5em 1.5em 0.8em;
		font-size: 1.1em;
	}

	.clicked {
		font-weight: bold;
		padding: 0.5em 1.5em 0.5em;
		border-bottom: solid 0.3em #117ffa;
	}

	&.product-nav {
		// border-bottom: solid 2px #f4f4f4;
		padding-left: 1.2em;
		padding-right: 1.2em;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none; /* Chrome, Safari, Opera*/
		}

		&.dashboard {
			border: none;
			padding: 0;

			.clicked {
				border-bottom: solid 0.3em #1a1a1a;
			}
		}

		button {
			font-weight: 500;
			color: #1a1a1a;
		}

		.clicked {
			font-weight: 700;
			border-bottom: solid 2px #1a1a1a;
		}
	}

	&.add-mobile-css {
		@media (max-width: 768px) {
			border: none;
			margin-bottom: 15px;
			padding-left: 0;
			padding-right: 0;
		}

		button {
			@media (max-width: 768px) {
				font-weight: 600;
				line-height: 1.2;
				padding: 0.45em 0.9em;
				color: #9d9996;
			}

			&.clicked {
				@media (max-width: 768px) {
					border-bottom: solid 2px #1a1a1a;
					color: #1a1a1a;
				}
			}
		}
	}

	@media (max-width: 768px) {
		button {
			white-space: nowrap;
		}
	}
}

.main-title {
	font-size: 2.2em;
	font-weight: bold;
	color: #343a40;
	margin-bottom: 0.5em;
	&.find-pwd {
		text-align: center;
		padding-top: 2em;
		font-size: 1.55em;
		line-height: 1;
		margin: 0;
		@media (max-width: 768px) {
			text-align: left;
			padding-top: 0;
			padding-bottom: 0.5em;
		}
	}
	@media (max-width: 768px) {
		font-size: 1.55em;
	}

	&.add-mobile-css {
		@media (max-width: 768px) {
			letter-spacing: -0.478px;
			color: #1a1a1a;
			font-weight: 600;
			font-size: 1.6em;
			line-height: 1.2;
			margin-bottom: 1em;
		}
	}
}

.main-description {
	font-size: 1em;
	color: #6d7580;
	font-weight: 500;

	@media (max-width: 768px) {
		color: #555555;
	}

	&.dashboard {
		@media (max-width: 768px) {
			font-size: 0.95em;
			line-height: 1.25;
			letter-spacing: initial;
		}
	}

	&.page {
		@media (max-width: 768px) {
			font-size: 1.1em;
			line-height: 1.05;
		}
	}
}

.product-link-banner-image {
	display: block;

	@media (max-width: 768px) {
		display: none;
	}
	&.mobile {
		display: none;

		@media (max-width: 768px) {
			display: block;
		}
	}
}

.main-body {
	display: flex;
	flex-direction: column;
	padding: 2em 0;

	@media (max-width: 768px) {
		padding: 0;
	}

	.body-title {
		font-size: 1.5em;
		font-weight: bold;
		display: flex;
		align-items: center;
		margin-bottom: 0.5em;

		img {
			width: auto;
			height: 1.3em;
		}
	}
}

.product-sort-popup {
	margin-top: 5px;
	.ant-popover-inner-content {
		padding: 5px 0;
	}

	&.ant-popover-placement-rightTop {
		.ant-popover-arrow {
			top: 0 !important;

			.ant-popover-arrow-content {
				transform: none;
				-webkit-filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.07));
				filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.07));
				box-shadow: none;

				&::before {
					clip-path: polygon(100% 0, 0 0, 100% 100%);
				}
			}
		}
	}

	&.ant-popover-placement-leftTop {
		.ant-popover-arrow {
			top: 0 !important;

			.ant-popover-arrow-content {
				transform: none;
				-webkit-filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.07));
				filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.07));
				box-shadow: none;

				&::before {
					clip-path: polygon(100% 0, 0 0, 0 100%);
				}
			}
		}
	}
}

.product-sort-menu {
	border: none;
	.ant-menu-item {
		height: fit-content;
		line-height: 16.8px;
		padding-top: 6px;
		padding-bottom: 6px;
		margin: 0;

		&.ant-menu-item-selected {
			background-color: transparent;
			color: inherit;
		}
	}
}

.product-list-wrap {
	padding: 1.5em 0;
	gap: 2.5%;
	@media screen and (max-width: 768px) {
		width: 100%;
		flex-wrap: wrap;
		gap: 4%;
	}

	.product-list-each {
		width: 18%;
		position: relative;

		@media screen and (max-width: 768px) {
			width: 48%;
			flex-wrap: wrap;
		}

		.sold-out {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.8);
			z-index: 1;
			color: white;
			font-size: 2em;
			font-weight: bold;
		}

		img {
			width: 100%;
			height: auto;
		}

		.commission-product-image-div {
			position: relative;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
			border-radius: 5px;

			&:after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 5px;
			}
		}

		.product-info-wrap {
			position: relative;
			gap: 4px !important;

			.ant-space-item {
				width: 100%;
			}

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 3px;
				padding: 0.5em;
				height: auto;
				flex: 1;
				width: 100%;
				border: 1px solid #d9d9d9;
				border-radius: 5px;

				&:hover,
				&:focus {
					box-shadow: none !important;
					outline: none;
					border-color: #d9d9d9;
				}

				img {
					width: auto;
					height: 0.9em;
				}

				span {
					font-size: 0.9em;
					font-weight: bold;
					color: #343a40;
				}
			}
		}

		&.dashboard {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;

			.commission-product-image-div {
				box-shadow: none;
				border-radius: 0;

				img {
					border-radius: 0;
				}
			}

			.product-info-wrap {
				order: 1;
				flex-direction: column-reverse;
				padding-bottom: 0 !important;

				button {
					border-radius: 3px;

					span {
						color: #9d9996;
					}

					&.product-link-btn {
						background-color: #eef6ff;
						border: none;

						span {
							color: #117ffa;
						}
					}
				}
			}

			.product-name-wrap {
				align-items: flex-start;
				text-align: left;
				padding-top: 0.5em;

				.product-etc-info-wrap {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					font-size: 0.9em;

					.commission-wrap {
						color: #fab011;

						span {
							color: #fab011;
						}

						&.high {
							color: #117ffa;

							span {
								color: #117ffa;
							}
						}
					}
				}

				.product-name {
					font-size: 1em;
					padding-top: 0;
				}
			}
		}

		&.product-link {
			.commission-product-image-div {
				@media screen and (max-width: 768px) {
					border: 0.5px solid #f2f2f2;
					border-radius: 5px;
					height: 9em;
					min-height: 109px;
				}

				img {
					@media screen and (max-width: 768px) {
						height: 100%;
						object-fit: contain !important;
					}
				}
			}

			.product-info-wrap {
				button {
					@media screen and (max-width: 768px) {
						padding: 0.8em 0;
						border-radius: 2.4px;
					}

					span {
						@media screen and (max-width: 768px) {
							font-size: 1em;
							font-weight: 600;
							line-height: 1.2;
						}
					}
				}
				.product-info-btn {
					@media screen and (max-width: 768px) {
						box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
						border: none;
					}
				}
			}

			.product-name-wrap {
				@media screen and (max-width: 768px) {
					padding-top: 0.8em;
				}

				.product-etc-info-wrap {
					.product-brand-name {
						@media screen and (max-width: 768px) {
							color: #555555;
							line-height: 1.2;
							font-weight: 500;
						}
					}

					.commission-wrap {
						@media screen and (max-width: 768px) {
							line-height: 1.2;
							font-weight: 500;
							font-size: 0.89em;
						}

						span {
							@media screen and (max-width: 768px) {
								line-height: 1.2;
								font-weight: 500;
							}
						}
					}
				}

				.product-name {
					@media screen and (max-width: 768px) {
						font-size: 1.2em;
						line-height: 1.2;
						color: #1a1a1a;
					}
				}
			}
		}
	}
}

.product-name-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	span {
		line-height: 1.4;
	}

	.product-brand-name {
		font-weight: normal;
	}

	.product-name {
		font-size: 1.3em;
		font-weight: 600;
		padding-top: 4px;
	}

	.commission-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 3px;
		color: #117ffa;
		font-weight: 600;

		span {
			font-weight: 600;
			color: #117ffa;
		}

		&.high {
			color: #ff0000;

			span {
				color: #ff0000;
			}
		}
	}
}
.product-link-modal-wrap .ant-modal-close {
	display: none !important;
}
.product-link-modal-wrap {
	.link-modal-title {
		font-size: 1.4em;
		font-weight: 600;
		padding-bottom: 1.55em;
	}
	.ant-modal {
		@media (max-width: 767px) {
			max-width: calc(100vw - 46px);
		}
	}
	.ant-modal-body {
		// padding: 5.5em 3.8em !important;

		@media (max-width: 768px) {
			padding: 4em 12px 1.5em;
		}
	}
	&.product-link-information-wrap {
		.product-link-information-modal-div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1em;

			h2 {
				margin: 0;
				font-size: 1.4em;
				color: #212529;
				font-weight: bold;
				letter-spacing: -0.8px;
			}

			h4 {
				margin: 0;
				font-size: 1.2em;
				color: #585858;
				font-weight: bold;
				letter-spacing: -0.8px;
				white-space: pre-wrap;
				text-align: center;
				@media (max-width: 768px) {
					font-size: 1.2em;
					font-weight: 600;
					color: #1a1a1a;
					line-height: 1.3;
				}

				span {
					color: #117ffa;
					font-weight: bold;
				}
			}

			p {
				font-size: 1em;
				font-weight: 500;
				color: #212529;
				letter-spacing: -0.5px;
				line-height: 1.5;
				white-space: pre-wrap;
				text-align: center;

				@media (max-width: 768px) {
					color: #1a1a1a;
					line-height: 1.45;
					word-break: keep-all;
				}
			}

			.product-link-information-btn {
				background-color: #117ffa;
				border-radius: 10px;
				padding: 13px 0;
				width: 100%;
				height: auto;
				margin-top: 0.5em;

				span {
					color: #ffffff;
					font-weight: 600;
					font-size: 1.2em;
					line-height: 1.2;
				}
			}
		}

		.product-link-information-modal-footer {
			border-top: solid 2px #bfbfbf;
			width: calc(100% + 48px);
			margin: 1em -24px -24px;

			display: flex;
			justify-content: center;
			align-items: center;

			border-bottom-left-radius: 28px;
			border-bottom-right-radius: 28px;
			overflow: hidden;
			@media (max-width: 768px) {
				width: calc(100% + 24px);
			}

			button {
				width: 100%;
				padding: 0.8em;
				flex: 1;

				&:first-child {
					border-right: solid 2px #bfbfbf;
				}

				font-size: 1.1em;
				font-weight: 500;
				color: #212529;
				letter-spacing: -0.6px;

				&.active {
					color: #117ffa;
					font-weight: bold;
				}
			}
		}
	}

	.ant-modal-content {
		border-radius: 8px;
		box-shadow: 3px 3px 3px 0 rgba(73, 80, 87, 0.12);
		border: solid 2px #bfbfbf;

		@media (max-width: 768px) {
			border-radius: 8px;
			border: none;
		}
	}
	.ant-modal-footer {
		display: none;
	}
	.product-link-create-copy-wrap {
		padding: 1.5em;
		display: flex;
		flex-direction: column;
		gap: 1.5em;

		.product-link-mention-div {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			@media (max-width: 768px) {
				flex-direction: column;
			}

			img {
				height: 4em;
				max-width: 20%;
				object-fit: cover;
				overflow: visible;
			}

			.product-link-mention-text-div {
				display: flex;
				flex-direction: column;
				justify-content: center;

				p.first-row {
					color: #686868;
					font-size: 1em;
					font-weight: 500;
					letter-spacing: -0.48px;
					text-align: center;
				}

				p.second-row {
					color: #000000;
					font-size: 1em;
					font-weight: 500;
					letter-spacing: -0.48px;
					text-align: center;

					span {
						font-size: 1.2em;
						font-weight: bold;
						color: #117ffa;
					}
				}
			}
		}

		.premium {
			position: relative;

			.product-link-img-premium-div {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			img {
				max-width: calc(100% + 2em);
				height: fit-content;
				object-fit: contain;
			}

			.product-link-mention-text-div {
				z-index: 1;

				p {
					color: #000 !important;
					font-weight: 500 !important;

					span {
						color: #117ffa;
						font-weight: bold;
					}
				}
			}
		}

		.product-link-copy-input-div {
			display: flex;
			align-items: center;
			gap: 1em;
			padding-bottom: 0.5em;
			@media screen and (max-width: 768px) {
				flex-direction: column;
			}

			input {
				border-radius: 5px;
				box-shadow: 3px 3px 3px 0 rgba(73, 80, 87, 0.12);
				border: solid 2px #bfbfbf;
			}

			.link-copy-button {
				background-color: #117ffa;
				border-radius: 6px;

				span {
					color: white;
				}
			}
		}
	}

	.product-link-create-description-wrap {
		padding: 0em;
		border-top: solid 2px #1a1a1a;
		border-bottom: solid 2px #1a1a1a;
		// 새로 추가 (리뉴얼)
		.product-link-flex-div {
			padding: 3em 4.5em;
			display: flex;
			align-items: center;
			&:first-of-type {
				border-bottom: 1px solid #d9d9d9;
			}
			> span {
				// text-align: center;
				min-width: 130px;
				line-height: 1;
				font-size: 1.1em;
				font-weight: 600;
			}
			.product-description {
				font-size: 0.9em;
				font-weight: 600;
			}
			.product-name {
				font-size: 1.5em;
				font-weight: 600;
				padding-bottom: 0.6em;
			}
			.content {
				padding-top: 10px;
				color: #9d9996;
				> span {
					color: #9d9996;
				}
			}
			.link-modal-content {
				display: flex;
				padding: 3.4em;
				border: 1px solid #d9d9d9;
				border-radius: 10px;
				gap: 3.3em;
				align-items: center;
				.thumbnail {
					width: 157px;
					height: 112px;
				}
			}
		}

		.link-create-features {
			display: flex;
			flex-direction: column;

			p {
				margin: 0;
				letter-spacing: -0.48px;
			}

			.title {
				font-size: 1.2em;
				font-weight: 700;
				text-align: left;
				color: #495057;
				margin-bottom: 0.5em;
			}
			.feature-list {
				padding-bottom: 1em;
				display: flex;
				gap: 0.5em;

				.content {
					font-size: 1em;
					color: #212529;

					span {
						color: #adb5bd;
						margin-right: 0.5em;
					}
				}
			}

			.link-feature-copy {
				padding-top: 1em;
				margin-top: 1em;
				// border-top: solid 2px #1a1a1a;
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					font-weight: bold;
					line-height: 2;
					color: #117ffa;
				}

				button {
					background-color: #343a40;
					border-radius: 6px;

					span {
						color: white;
					}
				}
			}
		}
	}
}

.new-link-modal .ant-modal-body {
	padding: 5.5em 3.8em !important;
}
.new-link-modal .ant-modal-content {
	margin-bottom: 5em;
}
.new-link-info-modal .ant-modal-body {
	padding: 3.3em 3.5em 2em;
}
.product-link-btn-wrap-home {
	display: flex;
	flex-direction: row;
	gap: 16px;
	justify-content: center;
	align-items: center;
	margin-top: 4em;
	> button {
		display: block;
		font-size: 1.05em;
		width: fit-content;
		padding: 1.1em 3em;
		box-sizing: border-box;
		border-radius: 8px;
	}
	> button.cancel-btn {
		width: 20%;
		color: #d9d9d9;
		background: #fff;
		border: 1px solid #d9d9d9;
	}
	> button.link-copy-button {
		width: 20%;
		background: #117ffa;
		color: #fff;
	}
}
.account-verification-modal-wrap {
	.ant-modal {
		width: 1030px !important;
		max-width: calc(100% - 40px) !important;
		border-radius: 10px;
		@media (max-width: 767px) {
			width: 100%;
			max-width: calc(100vw - 46px);
			top: 50px;
			margin: 0 auto;
			padding-bottom: 0;
			border-radius: 16px;
		}
	}

	.ant-modal-content {
		border-radius: 15px;
		height: calc(100vh - 80px);
		overflow-y: scroll !important;
		margin: -3em 0 5em;
		padding: 4em 3.5em;
		@media (max-width: 768px) {
			margin: 0;
			padding: unset;
		}
	}

	.ant-modal-body {
		padding: 35px 1.1em 20px;
		@media (min-width: 768px) {
			padding: 0;
		}
		.main-title {
			font-size: 1.5em;
			font-weight: 600;
			color: #1a1a1a;
			line-height: 1;
			padding-bottom: 2em;
			margin-bottom: 0;
			@media (max-width: 768px) {
				font-size: 1.3em;
				padding-bottom: 1em;
			}
		}

		.account-verification-form-wrap {
			display: flex;
			flex-direction: column;
			gap: 15px;

			.account-verification-form-item-wrap {
				display: flex;
				flex-direction: column;
				gap: 5px;
				@media (min-width: 768px) {
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #d9d9d9;
					padding: 2em 3em;
					gap: 0;

					&:first-of-type {
						border-top: 2px solid #1a1a1a;
						padding-bottom: 5em;
						position: relative;
						.help-text {
							@media (min-width: 768px) {
								position: absolute;
								bottom: 1.8em;
							}
						}
					}
					&:nth-of-type(4) {
						border-bottom: 2px solid #1a1a1a;
					}
				}
				label {
					display: flex;
					font-weight: 500;
					color: #1a1a1a;
					font-size: 1em;
					line-height: 1.3;
					@media (min-width: 768px) {
						min-width: 200px;
						font-size: 1.2em;
					}

					.item-required {
						color: #117ffa;
						margin-left: 2px;
						line-height: 1.2;
						font-weight: 500;
					}
				}

				.input-wrap {
					display: flex;
					align-items: center;
					@media (min-width: 768px) {
						width: 100%;
					}

					&.personal-number {
						gap: 9px;
						margin-bottom: 5px;
						@media (min-width: 768px) {
							margin-bottom: 0;
						}

						input {
							text-align: center;
							@media (min-width: 768px) {
								border-radius: 0;
							}
						}

						.divider {
							background-color: #d9d9d9;
							width: 9px;
							height: 2px;
						}
					}

					&.account-input {
						gap: 5px;

						.certification-request-btn {
							width: 35%;
							@media (min-width: 768px) {
								margin-left: 5px;
								width: 25%;
							}
						}

						.input-div-button-success {
							width: 48%;
							min-width: 92px;
							padding: 14px 0;
							@media (min-width: 768px) {
								margin-left: 5px;
								width: 25%;
							}
						}
					}

					input {
						padding: 15px 0.8em;
						color: #1a1a1a;
						font-weight: 500;
						font-size: 1.2em;
						line-height: 1.17;
						border-radius: 5px;
						@media (min-width: 768px) {
							border-radius: 0;
						}
					}

					.ant-input-affix-wrapper {
						border-radius: 5px;
						padding: 0 0.8em 0 0;
						@media (min-width: 768px) {
							border-radius: 0;
						}
					}
				}

				.help-text-wrap {
					display: flex;
					gap: 4px;
					margin-top: 5px;
				}

				.help-text {
					color: #1a1a1a;
					font-weight: 500;
					line-height: 1.3;
					white-space: pre-line;

					span {
						color: #117ffa;
						font-weight: 500;
						line-height: 1.3;
					}
				}

				.account-verification-code-label {
					font-size: 0.9em;
					line-height: 1.15;
					margin-top: 7px;
					margin-bottom: 2px;

					.text-highlight {
						color: #117ffa;
						line-height: 1.15;
						font-weight: 500;
					}
				}

				.account-verification-example-img-wrap {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 14px 1.5em;
					border: 1px solid #d8d8d8;

					.account-verification-example-img-item-wrap {
						display: flex;
						flex-direction: column;
						gap: 4px;

						.title {
							font-weight: 600;
							font-size: 0.9em;
							line-height: 1.15;
							color: #d9d9d9;
						}

						.description {
							color: #1a1a1a;
							font-weight: 600;
							font-size: 1.15em;
							line-height: 1.2;

							.code-wrap {
								background-color: #117ffa;
								border-radius: 2px;
								color: #ffffff;
								font-weight: 600;
								line-height: 1.2;
								letter-spacing: 0.33em;
								padding: 2px 0 0 6px;
								margin-left: 3px;
							}
						}

						&.end-item {
							justify-content: flex-end;

							p {
								text-align: right;
							}
						}
					}
				}

				&.identification-item {
					.certification-request-btn {
						width: 100%;
						@media (min-width: 768px) {
							margin-left: 10px;
							width: 25%;
						}
					}

					.input-div-button-success {
						width: 100%;
						@media (min-width: 768px) {
							margin-left: 10px;
							width: 25%;
						}
					}

					.help-text {
						font-size: 0.9em;
						margin-top: 5px;
					}
				}

				&.account-name-item {
					.help-text {
						font-size: 1em;
						word-break: keep-all;
					}
				}
			}

			.account-verification-form-btn-wrap {
				display: flex;
				flex-direction: column;
				gap: 4px;
				@media (min-width: 768px) {
					flex-direction: row-reverse;
					gap: 10px;
					justify-content: center;
					align-items: center;
					margin-top: 2em;
				}
				button {
					border: none;
					border-radius: 5px;
					padding: 0.9em 0;
					height: auto;
					@media (min-width: 768px) {
						padding: 1.15em 3.3em;
					}

					span {
						font-weight: 600;
						font-size: 1.15em;
						line-height: 1.2;
					}

					&.confirm {
						background-color: #117ffa;

						&:disabled {
							background-color: #d9d9d9;
						}

						span {
							color: #ffffff;
						}
					}

					&.cancel {
						@media (min-width: 768px) {
							border: 1px solid #d9d9d9;
						}
						span {
							color: #979797;
						}
					}
				}
			}
		}
	}
}

.dashboard-component-search-select-type-wrap {
	display: flex;
	align-items: center;
	gap: 5px;

	.ant-radio-button-wrapper {
		border-radius: 3px;
		@media (max-width: 767px) {
			padding: 0 7px;
			height: 30px;
			line-height: 26px;
			border-left-width: 1px;
		}

		&:not(&.ant-radio-button-wrapper-checked) {
			box-shadow: 0px 0px 2.8px rgba(0, 0, 0, 0.15);
			border: none;
		}

		&::before {
			background-color: transparent !important;
		}

		span {
			color: #9d9996;
			@media (max-width: 767px) {
				font-size: 1.2rem;
			}
		}

		&.ant-radio-button-wrapper-checked {
			span {
				color: #117ffa;
				@media (max-width: 767px) {
					font-size: 1.2rem;
				}
			}
		}
	}
}

.dashboard-component-search-date-wrap {
	color: #9d9996;
	border: none;

	@media (max-width: 768px) {
		max-width: 125px;
	}

	&:not(&.ant-picker-focused) {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	}

	.ant-picker-input > input {
		color: #9d9996;
		@media (max-width: 768px) {
			font-size: 1.2rem !important;
		}
	}
}

.gold-help-tooltip-wrap {
	white-space: pre-wrap;
	word-break: keep-all;
	width: 40vw;
	min-width: 135px;

	.ant-tooltip-content {
		border: 1px solid #117ffa;
		border-radius: 10px;

		.ant-tooltip-arrow {
			.ant-tooltip-arrow-content {
				background-color: #117ffa;

				&::before {
					top: -12px;
					left: -12px;
				}
			}
		}

		.ant-tooltip-inner {
			text-align: center;
			border-radius: 10px;

			p {
				font-weight: 500;
				font-size: 0.8em;
				line-height: 1.3;
				letter-spacing: -0.478px;
				color: #000000;

				span {
					font-weight: 500;
					line-height: 1.3;
					color: #117ffa;
				}
			}
		}
	}
}

/**
    * //////////////////////////////////////////////////////////////////////////////// *
    *                              [ Faq - 자주묻는 질문 ]           +      [ Notice - 공지사항 ]                       *
    * //////////////////////////////////////////////////////////////////////////////// *
**/
// 공지사항 탭
.ant-tabs-nav-wrap {
	@media (min-width: 768px) {
		background: #fff;
	}
}
.ant-tabs-ink-bar {
	background: #000 !important;
	@media (max-width: 768px) {
		background: #000 !important;
	}
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #000 !important;
	@media (max-width: 768px) {
		color: #000 !important;
	}
}

.ant-collapse-header {
	background: #fff !important;
	@media (max-width: 768px) {
		background: #fff !important;
		padding: 1.2em 1.4em !important;
	}
}

.ant-collapse-content-active > .ant-collapse-content-box {
	@media (max-width: 768px) {
		padding: 2.2em 0.5em !important;
		border-top: 1px solid #d9d9d9 !important;
	}
}
.ant-tabs-tab {
	@media (max-width: 768px) {
		padding: 0.7em 0.8em !important;
	}
}
.ant-tabs-tab + .ant-tabs-tab {
	margin: 0 0 0 10px !important;
}
.ant-tabs-tab-btn {
	@media (max-width: 768px) {
		color: #d9d9d9;
	}
}

.faq-custom-collapse {
	background-color: white !important;

	&.dashboard {
		.ant-collapse-item {
			&:not(.ant-collapse-item-active) {
				@media (max-width: 768px) {
					border-bottom: none;
				}
			}

			.ant-collapse-header {
				@media (min-width: 768px) {
					padding: 0.6em 1.4em !important;
				}

				.ant-collapse-header-text {
					@media (max-width: 768px) {
						margin-right: 12px;
						line-height: 1.2;
						letter-spacing: -0.03em;
					}

					.faq-custom-collapse-panel-header-question {
						@media (max-width: 768px) {
							font-weight: 600;
							line-height: 1.2;
						}
					}
				}
			}
		}
	}

	&.page {
		.faq-custom-collapse-panel {
			&.ant-collapse-item.ant-collapse-item-active {
				@media (max-width: 768px) {
					border-bottom: none;
				}
			}

			&.clicked {
				.ant-collapse-header {
					.ant-collapse-header-text {
						@media (max-width: 768px) {
							font-weight: 600;
						}

						p {
							@media (max-width: 768px) {
								font-weight: bold;
							}
						}
					}
				}
			}

			.ant-collapse-header {
				@media (max-width: 768px) {
					padding: 1.8em 1.4em !important;
				}

				.ant-collapse-header-text {
					@media (max-width: 768px) {
						font-size: 1.1em;
						font-weight: 500;
						line-height: 0.95;
						color: #1a1a1a;
						margin-right: 12px;
					}

					p {
						@media (max-width: 768px) {
							font-size: 1.1em;
							font-weight: 500;
							line-height: 0.95;
						}

						span {
							@media (max-width: 768px) {
								font-size: 1em;
								font-weight: 600;
								line-height: 0.95;
							}
						}
					}
				}
			}

			.ant-collapse-content {
				.ant-collapse-content-box {
					@media (max-width: 768px) {
						padding: 2.2em 2.4em !important;
					}

					p {
						@media (max-width: 768px) {
							padding: 0;
							font-weight: 500;
						}

						span {
							@media (max-width: 768px) {
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
}
.faq-custom-collapse.page {
	@media (min-width: 768px) {
		.ant-collapse-header-text {
			font-weight: 600;
			span {
				font-weight: 600;
			}
		}
	}
}
.faq-custom-collapse-panel .ant-collapse-content > .ant-collapse-content-box {
	@media (min-width: 768px) {
		background: #f8f9fa !important;
		padding: 2.5em 25px !important;
		font-size: 1.15em;
	}
}
.dashboard-list-content-box.notice .ant-tabs-nav-wrap {
	@media (max-width: 768px) {
		padding-left: 1.3em !important;
	}
}
.faq-custom-collapse-panel {
	.ant-collapse-header {
		@media (min-width: 768px) {
			padding: 30px 35px !important;
		}
	}
	&:not(&.dashboard) {
		@media (min-width: 768px) {
			padding: 0;
		}
		@media (max-width: 768px) {
			padding: 1em;
			padding: 0.5em;
			padding: 0;
		}
	}

	p {
		margin: 0;
		font-size: 1em;
		padding: 0 1em;
		white-space: pre-wrap;
	}

	.ant-collapse-header-text {
		font-size: 1.1em;
		font-weight: 400;
		@media (max-width: 768px) {
			font-size: 1em;
			font-weight: 500;
		}
	}

	&.clicked {
		background-color: #fff;
		@media (max-width: 768px) {
			background-color: #f8f9fa;
		}

		.ant-collapse-header-text {
			font-weight: 600;
		}

		.faq-custom-collapse-panel-header-question {
			font-weight: bold;
		}
	}
}

.faq-custom-collapse-panel-header-question {
	color: #117ffa;
	margin-right: 5px;

	@media (max-width: 768px) {
		margin-right: 7px;
	}
}

.dashboard-list-content-box {
	max-width: 1200px !important;
	margin: auto;
	margin-top: -2em;
	padding: 0 3em 6em;

	@media (max-width: 768px) {
		padding: 0 0 8em;
		margin-top: 0;
		min-height: 60vh;
	}

	&.notice {
		.ant-tabs-nav {
			@media (max-width: 768px) {
				margin: 0 0 3px 0;
			}

			&:before {
				@media (max-width: 768px) {
					border-bottom: none;
				}
			}

			.ant-tabs-nav-list {
				.ant-tabs-tab {
					padding: 1.15em 1.5em !important;
					@media (max-width: 768px) {
						padding: 6px 0.75em !important;
					}

					&.ant-tabs-tab-active {
						font-weight: 600;
						.ant-tabs-tab-btn {
							@media (max-width: 768px) {
								color: #1a1a1a !important;
							}
						}
					}

					.ant-tabs-tab-btn {
						color: #9d9996;
						@media (max-width: 768px) {
							font-weight: 500;
							font-size: 1em;
							line-height: 1.22;
							color: #9d9996;
						}
					}
				}
			}
		}
	}
}

.commission-button {
	border-radius: 6px;
	line-height: 1;
	height: 30px;
	border: none;

	span {
		color: white;
	}

	&.blue {
		background-color: #117ffa;
	}

	&.gray {
		background-color: #adb5bd;
	}
}

.report-settlement-row {
	margin: 1em;

	button {
		padding: 0.3em 4em;
		height: auto;

		&.click {
			background-color: #212529 !important;

			span {
				color: white !important;
			}
		}
	}
}

.settlement-info-div {
	background-color: #f8f9fa;
	display: flex;
	flex-direction: column;
	width: 100vw;
	padding: 2em 10em;
	margin-top: 3em;
	margin-bottom: -3em;
	margin-left: calc(-50vw + 50%);
	@media (max-width: 768px) {
		padding: 1em 1.2em;
		margin: 2em -1.2em -2em;
	}

	.settle-info-each {
		width: 100%;
		max-width: 1200px;
		margin: auto;
		border-bottom: solid 1px #ced4da;
		display: flex;
		align-items: center;
		gap: 3em;
		padding: 1em 0;

		&:last-child {
			border-bottom: none;
		}

		p.settle-title {
			margin: 0;
			width: 10%;
			font-size: 0.9em;
		}

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 1em;
			p.settle-title {
				width: 100%;
				font-weight: bold;
			}
		}
	}

	.settle-info-each-column {
		width: 100%;
		max-width: 1200px;
		margin: auto;
		display: flex;
		flex-direction: column;
		padding: 1em 0;
		gap: 2px;

		ul {
			padding-left: 1em;
		}

		p,
		li {
			font-size: 0.9em;
			margin: 0;
			color: #495057;
			white-space: pre-wrap;

			&.title {
				font-weight: bold;
			}
		}
	}

	.settle-image-group {
		display: flex;
		gap: 1em;
		align-items: center;

		.settle-image-description {
			p {
				margin: 0;
				font-size: 0.9em;

				&.sub {
					color: #495057;
				}

				span {
					font-size: 0.8em;
					color: #adb5bd;
				}
			}
		}
	}
}

.settle-dropdown {
	border: solid 1px #ced4da;
	border-radius: 10px;
	padding: 0.8em;
	flex-grow: 1;
	margin: 1em 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	button.settle-dropdown-button {
		width: 100%;
		justify-content: space-between;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}

	p {
		margin: 0;
		font-size: 0.9em;
	}

	.dropdown-target {
		display: flex;
		align-items: center;
		gap: 3px;

		.target-font {
			color: #117ffa;
			font-weight: bold;
		}
	}
}

/**
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
    *                                                    [ Common - 공통 컴포넌트 ]                                          *
    * /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// *
**/
.alert-custom,
.alert-custom-wide {
	padding-bottom: 0 !important;

	div.swal2-html-container {
		margin-top: 1.5em;
		font-size: 1.7em;
		font-weight: 600;
		white-space: pre-wrap;
		line-height: 1.2;
		display: flex;
		padding: 2em 0;
	}

	.swal2-actions {
		width: 100%;
		border-top: solid 0.7px #d1d1d1;

		button {
			flex: 1;
			margin: 0;
			background-color: transparent;
			outline: none !important;
			box-shadow: none !important;
			color: #717171;
			padding: 1em;
			font-size: 1.4em;
			border-right: solid 0.7px #d1d1d1;

			&.swal2-confirm {
				color: #117ffa;
			}

			&:last-child {
				border-right: none;
			}
		}
	}
}

.alert-custom-wide {
	width: 38rem;

	div.swal2-html-container {
		word-break: keep-all;
	}
}

.alert-custom-confirm {
	width: 38em;
	padding-bottom: 2em !important;

	.swal2-html-container {
		margin-top: 3em;
		font-size: 1.75em;
		font-weight: 500;
		white-space: pre-wrap;
		line-height: 1.2;

		@media (max-width: 768px) {
			font-size: 1.6em;
			line-height: 1.45;
			color: #1a1a1a;
			font-weight: 600;
		}
	}

	.swal2-actions {
		margin-top: 2em;
		gap: 5px;
		@media (max-width: 375px) {
			flex-direction: column;
			width: 100%;
		}

		@media (max-width: 768px) {
			width: 90%;
			justify-content: stretch;
		}

		button {
			font-size: 1.5em;
			font-weight: 600;
			margin: 0;
			width: 10em;
			@media (max-width: 375px) {
				width: calc(100% - 40px);
			}

			@media (max-width: 768px) {
				width: 100%;
			}

			&.swal2-cancel {
				color: #d9d9d9;
				border: 1px solid #d9d9d9 !important;
			}
		}
	}
}
// 로그아웃 버튼 순서 변경
.logout .swal2-actions {
	flex-direction: row-reverse !important;
}
.alert-custom-confirm2 {
	width: 38em;
	padding-bottom: 2em !important;
	.swal2-title {
		@media (max-width: 768px) {
			font-size: 1.6em;
			color: #1a1a1a;
			font-weight: 600;
			margin-top: 1em;
		}
	}
	.swal2-html-container {
		margin-top: 3em;
		font-size: 1.75em;
		font-weight: 500;
		white-space: pre-wrap;
		line-height: 1.2;

		@media (max-width: 768px) {
			font-size: 1.2em;
			line-height: 1.45;
			color: #1a1a1a;
			font-weight: 600;
			margin-top: 1em;
		}
	}

	.swal2-actions {
		margin-top: 2em;
		gap: 5px;
		@media (max-width: 375px) {
			flex-direction: column;
			width: 100%;
		}

		@media (max-width: 768px) {
			width: 90%;
			justify-content: stretch;
		}

		button {
			font-size: 1.5em;
			font-weight: 600;
			margin: 0;
			width: 10em;
			@media (max-width: 375px) {
				width: calc(100% - 40px);
			}

			@media (max-width: 768px) {
				width: 100%;
			}

			&.swal2-cancel {
				color: #d9d9d9;
				border: 1px solid #d9d9d9 !important;
			}
		}
	}
}

.not-found-wrap {
	width: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	gap: 1em;
	align-items: center;
	justify-content: center;

	h1 {
		text-align: center;
		font-size: 2em;
		font-weight: bold;
		color: white;
		letter-spacing: -0.9px;
		margin: 0;
		padding-top: 0.5em;
	}

	p {
		text-align: center;
		font-weight: 300;
		white-space: pre-wrap;
		color: white;
		letter-spacing: -0.9px;
		margin: 0;
	}

	button {
		border-radius: 20px;
		border: solid 2px #ffffff;
		background-color: rgba(255, 255, 255, 0.22);
		padding: 3px 30px;
		color: white;
		margin-top: 2em;
	}
}

.custom-pagination-div {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
	margin-top: 3em;

	@media (max-width: 768px) {
		gap: 10px;
	}

	.pagination-arrow-div {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 12px;
		width: fit-content;
		cursor: pointer;
	}

	.pagination-button {
		position: relative;
		width: 30px;
		height: 30px;
		border: none;

		span {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);

			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
			color: #979797;
		}

		&.active {
			span {
				font-weight: 600;
				color: #1a1a1a;
			}
		}
	}
}

.calculate-inquiry-select-wrap {
	.ant-dropdown-menu {
		.ant-dropdown-menu-item {
			.ant-dropdown-menu-title-content {
				font-size: 18px;
				color: #1a1a1a;
				font-weight: 600;
			}
		}
	}
}

.bank-dropdown-wrap {
	max-width: 500px;
	@media (max-width: 768px) {
		width: calc(100vw - 46px - 2.2em);
		max-width: none;
	}

	.ant-dropdown-menu {
		display: flex;
		flex-wrap: wrap;

		.ant-dropdown-menu-item {
			width: 50%;
			min-width: 140px;
		}
	}
}
