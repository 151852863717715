@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

html {
	font-size: 65%;
	margin: 0;
	height: 100%;
	-webkit-touch-callout: none;
}

body {
	font-size: 1.5rem !important;
	margin: 0;
	height: 100%;
	font-family:
		"Pretendard",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
}

code {
	font-family:
		source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

span,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
	color: #212529;
	line-height: 1.5;
	font-weight: normal;
}

button {
	background-color: white;
	border: none;
	cursor: pointer;
}

.toastui-editor-contents {
	font-family:
		"Pretendard",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif !important;
}
