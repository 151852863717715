.product-link-content-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.product-link-list-wrap {
	display: flex;
	flex-direction: column;
	gap: 11px;

	label {
		font-weight: 600;
		color: #1a1a1a;
		font-size: 1.2em;
		line-height: 1.2;
	}

	input {
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		font-weight: 500;
		font-size: 1.2em;
		line-height: 1.2;
		color: #979797;
		padding: 15px 1.1em;
	}

	.product-link-product-content-wrap {
		border: 1px solid #d9d9d9;
		border-radius: 10px;
		padding: 25px 1.2em;

		.product-link-product-img-wrap {
			width: 100%;
			height: 10em;
			min-height: 112px;
			margin-bottom: 20px;
			display: flex;
			justify-content: center;

			img {
				height: 100%;
				object-fit: contain;
			}
		}

		.product-link-create-description-wrap {
			.title {
				color: #1a1a1a;
				font-weight: 500;
				font-size: 0.9em;
				line-height: 1.18;
			}

			.product-name {
				color: #1a1a1a;
				font-weight: 600;
				font-size: 1.55em;
				line-height: 1.2;
				margin-top: 3px;
				margin-bottom: 10px;
			}

			.feature-list-wrap {
				display: flex;
				flex-direction: column;
				gap: 10px;

				.feature-list {
					display: flex;
					gap: 4px;

					p {
						color: #9d9996;
						font-weight: 500;
						font-size: 0.9em;
						line-height: 1.18;
					}
				}
			}
		}
	}
}

.product-link-btn-wrap {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	margin-top: 50px;

	button {
		width: 100%;
		height: auto;
		border-radius: 5px;
		padding: 16px 0;
		border: none;

		span {
			font-size: 1.2em;
			font-weight: 600;
			line-height: 1.2;
		}

		&.confirm {
			background-color: #117ffa;
			span {
				color: #ffffff;
			}
		}

		&.cancel {
			span {
				color: #9d9996;
			}
		}
	}
}
