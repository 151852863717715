.banner-wrap {
	position: relative;
	width: 100%;
	&.using-guide {
		@media (min-width: 768px) {
			margin-top: -6em;
		}
	}
	&.event-recommend {
		margin-top: -0.5em;
		@media (min-width: 768px) {
			margin-top: -4.5em;
		}
	}
	.banner-img {
		width: 100%;
		height: calc(100vh - 5em);

		@media (max-width: 768px) {
			height: 100%;
		}
	}

	.banner-title-wrap {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		left: 50%;
		text-align: center;

		&.recommend-friend {
			bottom: 10%;
			transform: translateX(-50%);

			@media screen and (max-width: 1024px) {
				bottom: 7%;
			}

			@media (max-width: 768px) {
				bottom: 13vw;
			}

			@media screen and (max-width: 400px) {
				bottom: 10vw;
				font-size: 1em;
			}

			@media screen and (max-width: 310px) {
				font-size: 0.85em;
			}

			.banner-title {
				color: #ffffff;
				font-weight: 700;
				font-size: 2.5em;
				line-height: 1.2;
				margin-bottom: 20px;

				@media (max-width: 768px) {
					font-size: 1.8em;
					margin-bottom: 10px;
				}
			}

			.banner-description {
				color: #ffffff;
				font-weight: 500;
				font-size: 1.3em;
				width: 60%;
				word-break: keep-all;

				@media (max-width: 768px) {
					width: 80vw;
					max-width: 340px;
					font-size: 1.25em;
					line-height: 1.2;
				}
			}
		}

		&.user-guide {
			top: 50%;
			transform: translate(-50%, -50%);

			@media (max-width: 768px) {
				width: 95%;
				top: 38%;
			}

			.banner-title {
				color: #1a1a1a;
				font-weight: 500;
				font-size: 2.5em;
				line-height: 1.4;
				margin-bottom: 0;
				white-space: pre-line;

				@media (max-width: 768px) {
					font-size: 1.8em;
					word-break: keep-all;
					line-height: 1.45;
				}

				span {
					font-weight: 700;
					line-height: 1.4;
				}
			}

			.banner-description {
				margin-top: 30px;
				color: #1a1a1a;
				font-weight: 500;
				font-size: 1.1em;
				line-height: 1.4;

				@media (min-width: 1401px) {
					font-size: 1.2em;
				}

				@media (max-width: 768px) {
					margin-top: 12px;
					white-space: pre-line;
				}
			}
		}
	}
}

.content-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;

	&.event-banner-page {
		margin-top: 9em !important;
		margin-bottom: 9em !important;

		@media (max-width: 768px) {
			margin-top: 8em !important;
			margin-bottom: 8em !important;
		}
	}

	&.benefit-page {
		@media (max-width: 768px) {
			flex-direction: row-reverse;
			padding: 0 30px 8em 26px;
		}
	}

	&.bg-gray {
		background-color: #f6f6f6;
	}

	&.add-padding {
		padding-top: 8em;
		padding-bottom: 12em;

		@media (max-width: 768px) {
			padding-top: 5em;
			padding-bottom: 8em;
		}
	}

	.content-title-wrap {
		&.pos-relative {
			position: relative;
			z-index: 5;
		}

		.content-title-category {
			text-align: center;
			color: #00a4ff;
			margin-bottom: 8px;
			font-weight: 600;
			font-size: 1.1em;

			@media (max-width: 768px) {
				font-size: 0.95em;
				line-height: 1.15;
				margin-bottom: 7px;
			}
		}

		.content-title {
			white-space: pre-line;
			text-align: center;
			margin-bottom: 0;
			color: #000000;
			font-weight: 500;
			font-size: 2em;

			@media (max-width: 768px) {
				font-size: 1.4em;
				line-height: 1.45;
			}

			span {
				color: #000000;
				font-weight: 600;

				@media (max-width: 768px) {
					line-height: 1.45;
				}
			}

			&.user-guide-page {
				font-size: 1.7em;
				margin-bottom: 90px;

				@media (max-width: 768px) {
					font-size: 1.45em;
					line-height: 1.4;
					margin-bottom: 55px;
				}

				span {
					position: relative;
					font-weight: 700;
					z-index: 2;

					@media (max-width: 768px) {
						line-height: 1.4;
					}

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: -3px;
						width: calc(100% + 6px);
						height: 40%;
						background-color: rgba(177, 225, 255, 0.5);
						z-index: -1;
					}
				}
			}

			&.benefit-page {
				margin-bottom: 70px;

				@media (max-width: 768px) {
					margin-bottom: 16px;
					word-break: keep-all;
				}

				@media screen and (max-width: 480px) {
					word-break: keep-all;
					padding: 0 4vw;
				}

				span {
					position: relative;
					z-index: 2;

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: -2px;
						width: calc(100% + 4px);
						height: 40%;
						background-color: rgba(177, 225, 255, 0.5);
						z-index: -1;
					}
				}
			}

			&.benefit-page-2 {
				margin-bottom: 60px;

				@media (max-width: 768px) {
					margin-bottom: 16px;
				}
			}
		}

		.content-title-bg-img {
			position: absolute;
			right: -12%;
			bottom: 5px;
			width: 48%;
			z-index: -1;
		}
	}

	.content-description-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		@media (max-width: 768px) {
			gap: 17px;
			padding: 0 10%;
		}

		&.benefit {
			align-items: flex-start;
			border-radius: 20px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
			gap: 0;
			background-color: #ffffff;
			width: 45vw;
			padding: 4.5em 5%;
			max-width: 838px;
			> div {
				&:first-of-type {
					padding-top: 0;
				}
				&:last-of-type {
					padding-bottom: 0;
					border-bottom: 0;
				}
				width: 100%;
				padding: 4em 0;
				border-bottom: 1px solid #f6f6f6;
			}
			@media (max-width: 768px) {
				border-radius: 10px;
				box-shadow: none;
				gap: 0;
				width: calc(100vw - 60px);
				padding: 38px 24px 38px 24px;
				> div {
					&:first-of-type {
						padding-top: 0;
					}
					&:last-of-type {
						padding-bottom: 0;
						border-bottom: 0;
					}
					width: 100%;
					padding: 2.3em 0;
					border-bottom: 1px solid #f6f6f6;
				}
			}
		}

		.content-description-div {
			border: 1px solid #00a4ff;
			box-shadow: 0px 2px 4px rgba(0, 164, 255, 0.1);
			border-radius: 15px;
			padding: 60px 10%;
			width: 45vw;
			min-width: 630px;

			@media screen and (max-width: 1024px) {
				width: 60vw;
				min-width: initial;
				max-width: 630px;
			}

			@media (max-width: 768px) {
				width: 100%;
				padding: 23px 15%;
				border: 0.5px solid #00a4ff;
				box-shadow: 0px 1px 2px rgba(0, 164, 255, 0.1);
				border-radius: 7.5px;
			}

			.content-description-text {
				color: #000000;
				text-align: center;
				font-weight: 500;
				font-size: 1.3em;
				white-space: pre-line;
				word-break: keep-all;

				@media (max-width: 768px) {
					font-size: 1em;
				}

				span {
					color: #00a4ff;
					font-weight: 600;
				}
			}
		}

		.content-description-item {
			.title {
				margin-bottom: 5px;
				color: #00a4ff;
				font-weight: 600;
				font-size: 1.6em;
				line-height: 1.22;

				@media (max-width: 768px) {
					margin-bottom: 7px;
					font-size: 1.28em;
					line-height: 1.2;
				}
			}

			.description {
				color: #1a1a1a;
				font-weight: 500;
				font-size: 0.8em;
				line-height: 1.45;
				white-space: pre-line;
				word-break: keep-all;

				@media (max-width: 768px) {
					font-size: 0.95em;
				}

				@media (min-width: 1401px) {
					font-size: 1em;
				}

				p {
					font-weight: 500;
					line-height: 1.45;
				}
			}
		}
	}

	.benefit-list-wrap {
		display: flex;
		gap: 2.2em;

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 10px;
		}

		.benefit-list-item {
			display: flex;
			flex-direction: column;
			gap: 15px;
			background-color: rgba(177, 225, 255, 0.1);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
			border-radius: 15px;
			width: 18vw;
			min-width: 270px;
			padding: 5% 30px;

			@media screen and (max-width: 1024px) {
				width: 30%;
				min-width: 200px;
				gap: 5px;
				box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
				border-radius: 7.5px;
				padding: 30px;
			}

			@media (max-width: 768px) {
				width: 100%;
				min-width: initial;
			}

			.title {
				color: #00a4ff;
				font-weight: 600;
				font-size: 1.15em;
				line-height: 1.3;

				@media (max-width: 768px) {
					font-size: 0.95em;
					line-height: 1.2;
				}
			}

			.description {
				color: #1a1a1a;
				font-weight: 500;
				font-size: 1.5em;
				white-space: pre-line;

				@media (max-width: 768px) {
					font-size: 1.2em;
					line-height: 1.4;
				}

				span {
					font-weight: 700;

					@media (max-width: 768px) {
						line-height: 1.4;
					}
				}
			}
		}
	}

	.benefit-item-line-img {
		width: 73%;
		height: auto;
		margin-top: -5px;

		@media screen and (max-width: 1024px) {
			width: 58%;
			min-width: 474px;
		}

		@media (max-width: 768px) {
			width: 6.3%;
			height: 100%;
			min-width: 20px;
			margin-top: 0;
		}

		@media screen and (max-width: 324px) {
			height: calc(calc(65px + 0.95em * 1.2 + 1.2em * 1.4 * 3) * 2 + 20px);
		}
	}

	.content-list-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 85px;
		width: 50vw;
		margin-bottom: 10em;

		@media screen and (max-width: 1024px) {
			width: 65vw;
		}

		@media (max-width: 768px) {
			width: calc(100% - 60px);
			gap: 30px;
			margin-bottom: 60px;
		}

		.content-list-item {
			border: 2px solid #00a4ff;
			box-shadow: 0px 2px 4px rgba(0, 164, 255, 0.1);
			border-radius: 15px;
			position: relative;
			padding: 60px 13%;
			width: 100%;

			@media (max-width: 768px) {
				border: 0.5px solid #00a4ff;
				box-shadow: 0px 1px 2px rgba(0, 164, 255, 0.1);
				border-radius: 7.5px;
				padding: 28px 7% 34px;
			}

			&.item-3th {
				@media (max-width: 768px) {
					padding: 28px 7% 20px;
				}
			}

			.content-list-item-category {
				position: absolute;
				top: calc(calc(1.2em * 1.2 + 32px) / 2 * -1);
				left: 50%;
				transform: translateX(-50%);
				background-color: #00a4ff;
				border-radius: 30px;
				padding: 16px 30px;
				color: #ffffff;
				font-weight: 600;
				font-size: 1.1em;
				line-height: 1.2;

				@media (max-width: 768px) {
					top: calc(calc(0.95em * 1.2 + 16px) / 2 * -1);
					font-size: 0.95em;
					padding: 8px 13px;
				}
			}

			.content-list-item-description {
				color: #1a1a1a;
				font-weight: 500;
				font-size: 1.3em;
				text-align: center;
				word-break: keep-all;

				@media (max-width: 768px) {
					font-size: 1.15em;
					line-height: 1.4;
					white-space: pre-line;
				}

				span {
					font-weight: 700;
				}

				&.ver-padding {
					padding: 1em 0;

					@media (max-width: 768px) {
						padding: 25px 0 9px;
						margin-left: -6%;
						margin-right: -6%;
					}
				}

				&.hor-padding {
					padding-left: 1.6em;
					padding-right: 1.6em;

					@media (max-width: 768px) {
						padding-left: 0;
						padding-right: 0;
					}
				}

				&.hor-padding-plus-m {
					@media (max-width: 768px) {
						padding-left: 8%;
						padding-right: 8%;
					}
				}

				&.hor-margin-minus-m {
					@media (max-width: 768px) {
						margin-left: -1%;
						margin-right: -1%;
					}
				}
			}

			.content-list-item-description-img {
				margin-top: 40px;

				@media (max-width: 768px) {
					margin-top: 12px;
				}
			}

			.content-list-item-description-img-text {
				text-align: center;
				color: #555555;
				font-weight: 500;
				font-size: 0.85em;

				@media (max-width: 768px) {
					font-size: 0.9em;
					padding-top: 7px;
				}

				&.add-padding-top-1 {
					padding-top: 1em;
				}
			}
		}
	}

	.padding-div {
		width: 100%;

		@media (max-width: 768px) {
			padding: 0 30px;
		}
	}

	.content-warning-text-wrap {
		background-color: #f6f6f6;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 73px 0 45px;

		@media (max-width: 768px) {
			padding: 40px 12% 30px;
		}

		.content-warning-title {
			color: #1a1a1a;
			font-weight: 700;
			font-size: 1.9em;
			margin-bottom: 0;
			line-height: 1.35;

			@media (max-width: 768px) {
				font-size: 1.4em;
				margin-bottom: 3px;
			}
		}

		.content-warning-sub-title {
			color: #1a1a1a;
			font-weight: 500;
			font-size: 1.25em;
			line-height: 1.4;
			text-align: center;

			@media (max-width: 768px) {
				font-size: 0.95em;
			}

			span {
				color: #00a4ff;
				font-weight: 600;
				line-height: 1.4;

				@media (max-width: 768px) {
					font-weight: 700;
				}
			}
		}

		.content-warning-quote-wrap {
			display: flex;
			margin-top: 15px;
			margin-bottom: 30px;

			@media (max-width: 768px) {
				margin-top: 8px;
				margin-bottom: 15px;
			}

			&:before {
				content: "\201C";
				font-size: 1.8em;
				font-family: Georgia;
				font-weight: 600;
				color: #1a1a1a;

				@media (max-width: 768px) {
					font-size: 1.45em;
					font-weight: 700;
				}
			}

			&:after {
				content: "\201D";
				font-size: 1.8em;
				font-family: Georgia;
				font-weight: 600;
				color: #1a1a1a;

				@media (max-width: 768px) {
					font-size: 1.45em;
					font-weight: 700;
				}
			}
		}

		.content-warning-quote {
			color: #1a1a1a;
			text-align: center;
			font-weight: 600;
			font-size: 1.25em;
			white-space: pre-line;
			word-break: keep-all;
			margin: 15px 8px 0;

			@media (max-width: 768px) {
				font-size: 0.95em;
				line-height: 1.35;
				margin: 10px 4px 0;
			}
		}

		.content-warning-help-text {
			color: #555555;
			font-weight: 500;
			font-size: 0.85em;
			line-height: 1.15;

			@media (max-width: 768px) {
				font-size: 0.8em;
			}
		}
	}

	.content-one-more-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 10em;
		margin-bottom: 10em;

		@media (max-width: 768px) {
			margin-top: 60px;
			margin-bottom: 13em;
		}

		.title {
			font-weight: 600;
			color: #1a1a1a;
			font-size: 1.8em;
			margin-bottom: 0;
			line-height: 1.3;
			position: relative;
			z-index: 2;

			@media (max-width: 768px) {
				font-size: 1.45em;
			}

			span {
				font-weight: 700;
				line-height: 1.3;
				z-index: 2;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: -17px;
				width: calc(100% + 34px);
				height: 40%;
				background-color: rgba(177, 225, 255, 0.5);
				z-index: -1;

				@media (max-width: 768px) {
					left: -8px;
					width: calc(100% + 16px);
				}
			}
		}

		.description {
			white-space: pre-line;
			text-align: center;
			margin-top: 15px;
			margin-bottom: 5px;
			font-weight: 500;
			color: #555555;
			font-size: 1em;
			line-height: 1.55;

			@media (max-width: 768px) {
				font-size: 0.95em;
				line-height: 1.3;
				margin-top: 8px;
				margin-bottom: 20px;
			}
		}

		.content-one-more-example-wrap {
			display: flex;
			width: 70%;

			@media (max-width: 768px) {
				width: 60%;
			}
		}

		.content-one-more-example-item {
			display: flex;
			flex-direction: column;
			align-items: center;

			.text {
				margin-top: -13px;
				font-weight: 500;
				color: #1a1a1a;
				font-size: 0.8em;
				line-height: 1.25;
			}

			.content-list-item-description-text-wrap {
				@media (max-width: 768px) {
					display: flex;
					justify-content: space-around;
					width: 100%;
					margin-top: 12px;
				}

				.text {
					@media (max-width: 768px) {
						margin-top: 0;
						font-size: 0.72em;
					}
				}
			}
		}
	}
}

// 체헙단 페이지 목업 css -kth
.main-wrap.event-supporter {
	h1 {
		font-size: 1.6em;
		font-weight: 700;
		margin: 0;
		padding: 1.5em 0 0.8em;
		border-bottom: 1px solid #d9d9d9;
	}
	.summary-info-box {
		width: 100%;
		margin: 2em 0 2em;
		div.flex-summary-info {
			display: flex;
			gap: 2em;

			.title {
				min-width: 90px;
				line-height: 33px;
				font-size: 1.1em;
				color: #555;
			}
			.desc {
				line-height: 33px;
				font-size: 1.23em;
				color: #000;
				letter-spacing: -0.5px;
			}
		}
	}

	.support-detail-wrap {
		padding: 3em 0 2em;
		border-bottom: #d9d9d9 1px solid;
		h2 {
			color: #1a1a1a;
			font-size: 1.23em;
			font-weight: 700;
			padding-left: 7px;
			border-left: 4px solid #117ffa;
			line-height: 1.2;
		}
		h3 {
			color: #1a1a1a;
			font-size: 1em;
			font-weight: 700;
			padding: 7px 0 0 10px;
			line-height: 20px;
			// margin: 0 3px;
		}

		.suport-icon-list {
			display: flex;
			align-items: center;
			padding: 0 1.5em;
			margin: 2em 0 2.5em;
			> div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: calc(100% / 3);
				gap: 0.5em;
			}
		}

		p.highlight-text {
			font-size: 1.23em;
			font-weight: 700;
			text-align: center;
			width: fit-content;
			padding: 0 5px;
			position: relative;
			// margin: 0 auto;
			margin-bottom: 1em;
			&::after {
				display: block;
				content: "";
				position: absolute;
				background: #00a4ff;
				opacity: 0.2;
				height: 6px;
				width: 100%;
				left: -0px;
				bottom: 5px;
				@media (max-width: 368px) {
					display: none;
				}
			}
		}

		.support-detail-desc {
			p.style2 {
				display: flex;
				gap: 7px;
				span {
					padding-left: 0;
				}
			}
			p.style3 {
				> span {
					display: inline-block;
					padding-left: 0;
				}
			}

			.style3 {
				display: flex;
				gap: 7px;

				a {
					color: #1890ff;
				}
			}
			p {
				font-size: 1.06em;
				font-weight: 400;
				color: #1a1a1a;
				white-space: pre-wrap;

				&.strong {
					font-weight: 600;
					padding-bottom: 0.9em;
				}

				> span {
					display: block;
					padding-left: 2.8em;
					position: relative;
					// line-height: 20px;
					padding-bottom: 4px;
					&.line {
						&::after {
							display: block;
							content: "-";
							position: absolute;
							top: 0;
							left: 26px;
						}
					}
				}
				> span {
					&.line2 {
						padding-left: 1em;
						&::after {
							display: block;
							content: "-";
							position: absolute;
							top: 0;
							left: 0px;
						}
					}
				}
			}
		}
	}
	.submit-support-btn {
		width: 100%;
		background: #117ffa;
		color: #fff;
		padding: 13px 0;
		width: 100%;
		font-size: 1.22em;
		border-radius: 8px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 3em;
	}
}
