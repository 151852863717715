// GoldSettlementRequestComponent.jsx
.gold-settlement-request-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 9em);
}

.gold-settlement-request-form-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.divider {
		width: calc(100% + 2.4em);
		height: 5px;
		background-color: #f8f9fa;
		margin-left: -1.2em;
		margin-right: -1.2em;
	}
}

.gold-settlement-request-item {
	display: flex;
	flex-direction: column;
	gap: 15px;

	.gold-info-text {
		color: #1a1a1a;
		font-weight: 600;
		font-size: 1.2em;

		span {
			color: #117ffa;
			font-weight: 600;
			font-size: 1em;
		}
	}

	.gold-input-wrap {
		display: flex;
		gap: 5px;

		.ant-input-affix-wrapper {
			border: 1px solid #d9d9d9;
			border-radius: 5px;
			padding: 14px 1em;

			input {
				color: #117ffa;
				font-weight: 600;
				font-size: 1.3em;
				line-height: 1.2;
				text-align: right;
			}

			input::placeholder {
				text-align: left;
				color: #d9d9d9;
				font-weight: 500;
				font-size: 1em !important;
			}

			.ant-input-suffix {
				color: #1a1a1a;
				font-weight: 600;
				font-size: 1em;
				line-height: 1.2;
			}
		}

		.full-settlement-button {
			background-color: #eef6ff;
			border: none;
			border-radius: 5px;
			height: auto;
			padding: 0 1.2em;

			&:disabled {
				background-color: #d9d9d9;

				span {
					color: #ffffff;
				}
			}

			span {
				font-size: 1em;
				font-weight: 600;
				line-height: 1.22;
				color: #117ffa;
			}
		}
	}

	.help-text-wrap {
		display: flex;
		flex-direction: column;
		gap: 2px;
		margin-top: 7px;

		p {
			color: #d9d9d9;
			font-size: 0.9em;
			line-height: 1.15;
		}
	}

	.gold-settlement-request-input-wrap {
		display: flex;
		flex-direction: column;
		gap: 5px;

		input {
			border: 1px solid #d9d9d9;
			border-radius: 5px;
			color: #1a1a1a;
			font-weight: 500;
			font-size: 1.2em;
			line-height: 1.2;
			padding: 15px 1em;
		}
	}
}

// GoldSettlementRequestCompleteComponent.jsx
.gold-settlement-request-complete-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3em 2.7em 0;

	.title {
		color: #1a1a1a;
		font-weight: 600;
		font-size: 1.9em;
		line-height: 1.3;
		white-space: pre-line;
		text-align: center;
		margin-bottom: 24px;
	}

	.gold-settlement-div {
		display: flex;
		justify-content: center;
		align-items: baseline;
		background-color: #117ffa;
		border-radius: 15px;
		padding: 30px 0;
		font-weight: 600;
		font-size: 1.2em;
		line-height: 1.2;
		width: 100%;
		max-width: 243px;
		color: #ffffff;

		span {
			color: #ffffff;
			font-weight: 600;
			font-size: 1.7em;
			line-height: 1.2;
			margin-right: 5px;
		}

		&.tooltip {
			position: relative;
			margin-bottom: 30px;
			background: #ffffff;
			border: 2px solid #117ffa;
			color: #1a1a1a;

			&:after {
				content: "";
				position: absolute;
				border-style: solid;
				border-width: 15px 20px 0 20px;
				border-color: #ffffff transparent;
				display: block;
				width: 0;
				z-index: 1;
				bottom: -13px;
				left: 50%;
				transform: translateX(-50%);
			}

			&:before {
				content: "";
				position: absolute;
				border-style: solid;
				border-width: 15px 20px 0 20px;
				border-color: #117ffa transparent;
				display: block;
				width: 0;
				z-index: 0;
				bottom: -15.5px;
				left: 50%;
				transform: translateX(-50%);
			}

			span {
				color: #117ffa;
			}
		}
	}

	.help-text {
		color: #d9d9d9;
		font-size: 1.1em;
		line-height: 1.2;
		width: 100%;
		max-width: 243px;
		margin-top: 8px;
	}
}
