// SignUpComponent.jsx

.signup-form-parents {
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding: 0 3em;
	@media (max-width: 768px) {
		//border: solid 1px #dee2e6;
		padding: 0;
		h1 {
			display: none;
		}
	}

	.point-text {
		color: #343a40;
		font-size: 1em;
		font-weight: 300;
		letter-spacing: -0.75px;
		line-height: 1.4;
		white-space: pre-wrap;

		span {
			color: #117ffa;
		}
	}

	.signup-component-list {
		display: flex;
		flex-direction: column;

		h2 {
			background-color: rgba(236, 244, 254, 0.8);
			font-size: 1.4em;
			font-weight: bold;
			line-height: 1.3;
			padding: 0.8em 1.2em;
			letter-spacing: -1px;
			color: #212529;
			@media (max-width: 768px) {
				font-size: 1.4em;
				padding: 1.3em 0.9em;
				background-color: #f0f6fe;
				color: #1a1a1a;
				font-size: 1.3em;
				line-height: 1.2;
			}
		}
	}

	.signup-form-wrap {
		display: flex;
		flex-direction: column;
		border-top: solid 2px #000;
		padding: 1.5em 0;

		@media (max-width: 768px) {
			border-top: none;
			padding: 0;
			gap: 15px;
		}
		//  -- 비밀번호 찾기 추가 ---
		&.find-pwd {
			border: none;
		}

		.find-pwd-box {
			width: 680px;
			max-width: 100%;
			margin: 0 auto;
			box-sizing: border-box;
		}

		.item-required-div {
			position: relative;
			width: 5px;
			margin-left: 10px;
		}

		.item-required {
			position: absolute;
			color: #117ffa;
			font-weight: 500;
			line-height: 1.55;
			letter-spacing: -1px;
			top: -2px;
			left: 2px;
		}

		.signup-form-item {
			margin: 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			// background: red;
			@media (max-width: 768px) {
				flex-direction: column;
				align-items: stretch;
				gap: 5px;
			}
			// 비밀번호 찾기
			&.find-pwd {
				flex-direction: column;
				align-items: flex-start;
			}
			&.divider {
				border-bottom: solid 1px #d1d1d1;
				padding: 1.5em 5em;
				flex-wrap: wrap;
				position: relative;
				@media (max-width: 768px) {
					padding: 0;
					border-bottom: none;
				}

				&:first-child {
					padding-top: 0;
				}
			}

			&.padding {
				padding: 1.5em 5em;

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					padding-top: 0;
				}

				@media (max-width: 768px) {
					padding: 0;
					//&:last-child {
					//	padding-top: 1em;
					//	padding-bottom: 2em;
					//}
				}
			}

			label {
				font-size: 1.12em;
				color: #1a1a1a;
				font-weight: 500;
				letter-spacing: -1px;
				width: 28%;
				display: flex;
				align-items: center;
				gap: 5px;
				> span {
					color: #117ffa;
				}
				@media (max-width: 768px) {
					width: 100%;
					line-height: 1.2;
				}
			}

			input {
				padding: 0.5em 1em;
			}

			.input-div {
				display: flex;
				align-items: center;
				gap: 0.5em;
				width: 60%;

				@media (max-width: 768px) {
					width: 100%;
					align-items: initial;
				}

				.input-div-button {
					background-color: #eef6ff;
					font-size: 16px;
					color: #117ffa;
					font-weight: 600;
					padding: 14px 26px;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 30%;
					border-radius: 6px;
					cursor: pointer;
				}

				input {
					height: 2.8em;
					font-size: 18px;

					@media (max-width: 768px) {
						font-size: 16px;
						line-height: 19px;
						color: #1a1a1a;
						border: 1px solid #d9d9d9;
						border-radius: 5px;
						height: 3.1em;

						&::placeholder {
							color: #d9d9d9;
						}
					}
				}

				&.sns {
					width: 60%;
					height: 2.8em;
					border: 1px solid #d9d9d9;
					padding-left: 15px;
					font-size: 18px;
					font-weight: 500;

					@media (max-width: 768px) {
						width: 60%;
					}

					button {
						border-radius: 16px;

						span {
							color: #818a94;
						}
					}
				}

				&.name {
					width: 60%;
					@media (max-width: 768px) {
						width: 60%;
					}

					button {
						border-radius: 16px;

						span {
							color: #818a94;
						}
					}
				}

				&.phone {
					width: 60%;
					@media (max-width: 768px) {
						width: 100%;
					}

					button {
						border-radius: 16px;

						span {
							color: #818a94;
						}
					}
				}

				&.personal-number {
					display: flex;
					flex-direction: row;
					align-items: center;
					width: 60%;
					@media (max-width: 768px) {
						flex-direction: column;
					}
				}

				.personal-number-layout {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 0.5em;
					width: 50%;

					input {
						width: 100%;
						@media (max-width: 768px) {
							width: 100%;
						}
					}
				}
			}

			.help-text-wrap {
				padding-top: 3px;
				padding-left: 8px;

				.help-text {
					font-size: 0.9em;
					font-weight: 500;
					line-height: 1.3;
					color: #d9d9d9;
				}
			}

			.url-list-wrap {
				display: flex;
				flex-direction: column;
				padding: 18px 0 0 28%;
				width: 100%;

				@media (max-width: 768px) {
					padding: 10px 6px 13px 15px;
				}

				.url-list-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 14px;
					width: 83%;
					padding: 0 2%;

					@media (max-width: 768px) {
						width: 100%;
					}

					.url-text {
						width: calc(100% - 10px);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #555555;

						@media (max-width: 768px) {
							font-size: 1.2em;
							line-height: 1.2;
						}
					}

					.url-delete-img {
						width: 10px;
						height: 10px;
						cursor: pointer;
					}
				}
			}
		}

		.signup-agreement-wrap {
			display: flex;
			flex-direction: column;
			gap: 0.5em;
			font-size: 1rem;
			border-bottom: solid 1px #d1d1d1;
			padding: 1.5em 1em;

			@media (max-width: 768px) {
				border-bottom: none;
				flex-direction: row;
				gap: 10px;
				padding: 0 1em;
			}

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				@media (max-width: 768px) {
					border-bottom: none;
				}
			}

			.signup-agreement-text-wrap {
				display: flex;
				flex-direction: column;
				gap: 5px;

				p {
					font-weight: 500;
					font-size: 1.2em;
					line-height: 1.2;
					color: #979797;
				}
			}

			.signup-agreement-div {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 0.8em;

				p {
					@media (max-width: 768px) {
						font-weight: 600;
						font-size: 1.6em;
						line-height: 1.2;
						color: #979797;
					}
				}
			}

			img {
				width: 1.4em;
				height: 100%;
				object-fit: contain;
				cursor: pointer;
			}

			p {
				font-size: 1.4em;
				font-weight: 500;
				line-height: 1.55;
				letter-spacing: -1px;
				color: #767f88;
			}
		}
	}
}

.signup-form-divider {
	width: calc(100% + 2.4em);
	height: 11px;
	background-color: #f6f6f6;
	margin: 25px -1.2em 21px;
}

.input-div-button-success {
	padding: 14px 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	border-radius: 6px;
	cursor: auto;
	border: 1px solid #117ffa;
	gap: 4px;
	cursor: pointer;
	span {
		font-weight: 600;
		font-size: 16px;
		color: #117ffa;
	}

	&.signup-btn {
		// max-width: 110px;
		min-width: 100px;
		padding: 0;
	}
}

.certification-request-btn {
	padding: 14px 0;
	display: flex;
	justify-items: center;
	align-items: center;
	width: 30%;
	height: auto;
	border-radius: 6px !important;

	&:not([disabled]) {
		background-color: #eef6ff;
		color: #117ffa;
		border-color: transparent;

		span {
			color: #117ffa;
		}
	}

	span {
		color: inherit !important;
		width: 100%;
		font-weight: 600;
		font-size: 16px;
	}

	&.signup-btn:disabled {
		background-color: #d9d9d9;
		border: none;
		color: #ffffff;
	}
}

// SignUpHeader.jsx
.signup-header {
	display: none;
	position: fixed;
	box-shadow: none;

	@media (max-width: 768px) {
		display: flex;
	}

	padding: 0 !important;
	align-items: center;
	//justify-content: space-between;
	justify-content: center;

	p {
		margin: 0;
		font-size: 1.5em;
		font-weight: bold;
		letter-spacing: -1px;
	}

	img {
		padding: 1em;
		width: 4em;
		height: 100%;
		object-fit: contain;
		position: absolute;
	}
}

.signup-agreement-btn-wrap {
	display: flex;
	gap: 8px;
	width: 100%;
	align-items: center;
	justify-content: center;
	@media (max-width: 768px) {
		flex-direction: column;
		margin-top: 8em;
	}
}

.signup-blue-button-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5em 0;
	gap: 1em;
	margin-top: 3em;

	@media (max-width: 768px) {
		justify-content: stretch;
		padding: 0;
		margin-top: 2.5em;
		margin-bottom: 2em;
	}

	.signup-blue-button {
		width: fit-content;
		margin-top: 0;
		background-color: #117ffa;
		border-radius: 8px;
		padding: 1em 3.3em;
		height: auto;

		@media (max-width: 768px) {
			margin-top: 0;
			width: 100%;
			border-radius: 5px;
			background-color: #d9d9d9;
			padding: 16px 0;
			border: none;

			&:not([disabled]) {
				background-color: #117ffa;
				color: #ffffff;
			}
		}

		&.white {
			&:hover {
				border: 1.5px solid #d9d9d9;
			}
			span {
				color: #d9d9d9;
			}
		}
		span {
			color: #ffffff;
			font-size: 1.1em;
			font-weight: 500;
			letter-spacing: -0.5px;

			@media (max-width: 768px) {
				font-size: 1.2em;
				font-weight: 600;
				line-height: 1.2;
				letter-spacing: initial;
			}
		}
	}

	.signup-agreement-cancel-button {
		border-radius: 5px;
		padding: 1em 3em;
		height: auto;
		&:hover {
			border: 1.5px solid #d9d9d9;
		}
		@media (max-width: 768px) {
			padding: 16px 0;
			border: none;
			height: auto;
			&:hover {
				border: none;
			}
		}

		span {
			font-size: 1.2em;
			font-weight: 600;
			line-height: 1.2;
			color: #d9d9d9;
		}
	}
}

.signup-fixed-bottom {
	display: none;
	position: fixed;
	bottom: 0;
	padding-bottom: 20px;
	width: calc(100% - 2.4em);

	@media (max-width: 768px) {
		display: block;
	}

	button {
		width: 100%;
		border-radius: 5px;
		background-color: #117ffa;
		padding: 16px 0;
		border: none;
		height: auto;

		&:disabled {
			background-color: #d9d9d9;
		}

		&:not([disabled]) {
			background-color: #117ffa;
			color: #ffffff;
		}

		span {
			color: #ffffff;
			font-size: 1.2em;
			font-weight: 600;
			line-height: 1.2;
		}
	}
}

.seller-user-banner-wrap {
	background-color: #117ffa;
	width: 100%;
	padding-bottom: 28rem;
	@media (max-width: 768px) {
		padding-bottom: 26rem;
	}

	.seller-grade-user-info {
		display: flex;
		gap: 3em;

		@media (max-width: 768px) {
			gap: 1em;
		}

		.seller-profile {
			width: 8em;
			height: 8em;
			min-width: 8em;
			background-color: white;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: 768px) {
				width: 6em;
				height: 6em;
				min-width: 6em;
			}

			img {
				width: 65%;
				height: 65%;
				object-fit: contain;
			}
		}

		.seller-user-div {
			display: flex;
			flex-direction: column;
			gap: 0.5em;
			@media (max-width: 768px) {
				gap: 0.2em;
			}

			.seller-setting {
				background-color: transparent;

				img {
					object-fit: cover;
					width: 1.5em;
					height: 1.5em;

					@media (max-width: 768px) {
						width: 1.2em;
						height: 1.2em;
					}
				}
			}

			h1 {
				margin: 0;
				font-size: 2.2em;
				font-weight: bold;
				color: white;
				line-height: 1.4;
				letter-spacing: -0.6px;

				@media (max-width: 768px) {
					font-size: 1.8em;
				}
			}

			h2 {
				margin: 0;
				font-size: 1.4em;
				font-weight: bold;
				color: white;
				line-height: 1.4;
				letter-spacing: -0.6px;
				@media (max-width: 768px) {
					font-size: 1.3em;
				}
			}

			p {
				margin: 0;
				font-size: 1em;
				color: white;
				line-height: 1.4;
				letter-spacing: -0.6px;
				@media (max-width: 768px) {
					font-size: 0.9em;
				}
			}

			.banner-text-pc {
				color: white;
				font-size: 1em;
				letter-spacing: -0.8px;
				line-height: 1.6;

				span {
					color: white;
					letter-spacing: -0.8px;
					font-weight: bold;
				}

				@media (max-width: 768px) {
					font-size: 1em;
					white-space: pre-wrap;
				}
			}
		}
	}
}

.seller-mygrade-banner {
	margin-top: 0 !important;
	overflow: visible;
}

.benefit-by-grade-button {
	margin: 5rem auto;
	border: solid 1px #444444;
	border-radius: 6px;
	padding: 0.8em 3.5em;
	height: auto;
	display: flex;
	align-items: center;
	gap: 0.3em;

	span {
		font-size: 1.1em;
		font-weight: bold;
		color: #343434;
		letter-spacing: -0.7px;
	}

	img {
		width: 1.1em;
		height: 1.1em;
		object-fit: cover;
	}
}
.secession-modal .ant-modal {
	@media (min-width: 768px) {
		width: 515px !important;
		max-width: 100% !important;
	}
}
.secession-modal .modal-title.secession {
	@media (min-width: 768px) {
		font-size: 1.4em;
		padding-top: 1.6em !important;
		margin-bottom: 0.5em !important;
	}
}
.secession-modal .ant-modal .secession-modal-cont-flex {
	@media (min-width: 768px) {
		display: flex;
		gap: 10px;

		> div {
			margin-bottom: 2.5em !important;
		}
	}
}
.seller-grade-modal-wrap {
	.ant-modal-content {
		border-radius: 8px;
		overflow: hidden;
		padding: 1em;
		@media (max-width: 768px) {
			padding: 0;
		}

		.ant-modal-header {
			border-bottom: none;

			.ant-modal-title {
				font-weight: bold;
			}
		}

		.ant-modal-footer {
			display: none;
		}

		.ant-modal-body {
			padding-top: 0;
		}

		.seller-grade-modal-div {
			display: flex;
			flex-direction: column;

			.seller-grade-modal-each {
				padding: 1em 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: solid 0.7px #d1d1d1;

				@media (max-width: 768px) {
					gap: 1em;
				}
				&:last-child {
					border-bottom: none;
				}

				.seller-grade-modal-info {
					display: flex;
					flex-direction: column;
					gap: 0.3em;
					width: 100%;

					p.title {
						font-size: 1.3em;
						font-weight: bold;
						color: #117ffa;
						letter-spacing: -0.5px;
						line-height: 2;
					}

					.seller-grade-modal-info-benefit-div {
						display: flex;
						gap: 6px;

						label {
							width: fit-content;
							font-size: 1.1em;
							font-weight: bold;
							letter-spacing: -0.6px;
							color: #212529;
							white-space: nowrap;

							@media (max-width: 768px) {
								font-size: 1em;
							}
						}

						.value {
							font-size: 1.1em;
							letter-spacing: -0.6px;
							color: #212529;
							white-space: pre-wrap;

							@media (max-width: 768px) {
								font-size: 1em;
							}
						}
					}
				}

				img {
					width: 5em;
				}
			}
		}
		.seller-grade-modal-cont {
			width: 298px;
			max-width: 100%;
			margin: 0 auto;
		}
		.modal-gold-settlement-div {
			display: flex;
			justify-content: center;
			align-items: baseline;
			background: #117ffa;
			border: 1px solid #117ffa;
			padding: 1em 0;
			font-weight: 600;
			font-size: 1.2em !important;
			color: #fff;

			&.tooltip {
				position: relative;
				margin-bottom: 30px;
				background: #fff;
				color: #000;
				span {
					color: #117ffa;
				}

				&:after {
					content: "";
					position: absolute;
					border-style: solid;
					border-width: 15px 20px 0 20px;
					border-color: #fff transparent;
					display: block;
					width: 0;
					z-index: 1;
					bottom: -14px;
					left: 50%;
					transform: translateX(-50%);
				}

				&:before {
					content: "";
					position: absolute;
					border-style: solid;
					border-width: 15px 20px 0 20px;
					border-color: #117ffa transparent;
					display: block;
					width: 0;
					z-index: 0;
					bottom: -15px;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			span {
				font-weight: 700;
				font-size: 1.8em;
				color: #fff;
				margin-right: 3px !important;
			}
		}

		.modal-gold-settlement-help {
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #d9d9d9;
			margin-top: 5px;
		}
	}
}

.custom-modal-wrap {
	.ant-modal {
		@media (max-width: 767px) {
			max-width: calc(100vw - 46px);
			margin: 0 auto;
			padding-bottom: 0;
		}
	}

	.ant-modal-content {
		@media (max-width: 768px) {
			border-radius: 15px;
		}
	}

	.ant-modal-body {
		@media (max-width: 768px) {
			padding: 35px 20px 20px !important;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.modal-title {
			@media (max-width: 768px) {
				padding-top: 0;
				white-space: pre-line;
			}
		}
		.modal-title.secession {
			@media (max-width: 768px) {
				font-size: 1.4em;
				margin-bottom: 0.2em;
			}
		}
		.modal-title-sub.secession {
			@media (max-width: 768px) {
				color: #979797;
				font-weight: 400;
				font-size: 1em;
			}
		}
		.modal-button-wrap {
			@media (max-width: 768px) {
				width: 100%;
				gap: 4px;
				margin-bottom: 0;
			}
		}
	}
}
.secession-title {
	font-weight: 600;
	color: #117ffa;
	font-size: 1.15em;
	line-height: 1.2;
	padding-bottom: 8px;
	@media (max-width: 768px) {
		font-size: 1.15em;
		font-weight: 600;
		color: #117ffa;
		padding-bottom: 0.4em;
		letter-spacing: -0.2px;
	}
}
.secession-desc {
	font-size: 0.9em;
	@media (max-width: 768px) {
		font-size: 0.88em;
		font-weight: 400;
		color: #000;
	}
}
.seller-grade-margin-top-wrap {
	display: flex;
	flex-direction: column;
	padding-bottom: 1em;
	margin-top: -30rem;
	@media (max-width: 768px) {
		margin-top: -28rem;
	}
}

.seller-my-grade-banner {
	margin: 1em 0;

	&:first-child {
		margin-top: 0;
	}

	padding: 2em 4.5rem;
	background-color: white;
	border-radius: 20px;
	box-shadow: 0 10px 49px 0 rgba(0, 0, 0, 0.16);
	@media (max-width: 768px) {
		padding: 1.8rem 2rem;
		border-radius: 16px;
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	position: relative;
	z-index: 1;

	.seller-grade-title-text {
		font-size: 1.8em;
		font-weight: bold;
		line-height: 1.4;
		letter-spacing: -0.8px;
		margin: 0;
		color: #000000;
		@media (max-width: 768px) {
			font-size: 1.3em;
		}

		&.reward {
			font-size: 1.9em;
			@media (max-width: 768px) {
				font-size: 1.4em;
			}
		}

		span {
			color: #117ffa;
			font-weight: bold;
		}
	}

	.grade-period-text {
		color: #a8a8a8;
		line-height: 1.5;
		letter-spacing: -1px;
		@media (max-width: 768px) {
			font-size: 0.9em;
		}
	}

	.my-grade {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4.5rem;
		font-size: 1.8em;
		font-weight: bold;
		line-height: 1.4;
		letter-spacing: -0.8px;
		margin: 0;
		color: #117ffa;
		@media (max-width: 768px) {
			font-size: 1.4em;
			right: 2rem;
		}
	}
}

.seller-reward-progress-wrap {
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 1rem 0;
	gap: 0.5em;

	.reward-progress-grade-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.progress-wrap {
		width: 100%;
		position: relative;

		.reward-progress {
			.ant-progress-outer {
				padding: 0 !important;

				.ant-progress-bg {
					background-color: #117ffa;
					height: 1.6em !important;
					@media (max-width: 768px) {
						height: 16px !important;
					}
				}
			}

			.ant-progress-text {
				display: none;
			}
		}

		.grade-point-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;

			&#hello {
				padding-left: 2%;

				&.text {
					padding-left: 1%;
				}
			}

			&#family {
				width: 100%;
				justify-content: center;
			}

			&#premium {
				padding-right: 2%;
				justify-content: flex-end;

				&.text {
					padding-right: 1%;
				}
			}

			.grade-point-wrap-inner {
				display: flex;
				align-items: center;
				padding-top: 1.25em;

				.grade-point-wrap-icon {
					width: 1.5rem;
					height: 1.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 0.25rem;
					overflow: hidden;

					@media (max-width: 768px) {
						width: 1.25rem;
						height: 1.25rem;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				p {
					color: #7b7b7b;
					font-size: 1.2em;
					font-weight: bold;
					letter-spacing: -1px;
					line-height: 1.7;
					@media (max-width: 768px) {
						font-size: 1.1em;
					}

					&.active {
						color: #117ffa;
					}

					@media (max-width: 768px) {
						font-size: 1em;
					}
				}
			}
		}

		.grade-point {
			width: 0.75em;
			height: 0.75em;
			border-radius: 50%;
			background-color: #585858;

			&.active {
				background-color: white;
			}

			@media (max-width: 768px) {
				width: 7px;
				height: 7px;
			}
		}
	}
}

.reward-purchase-amount {
	padding-top: 1em;
	font-size: 1.1em;
	font-weight: 500;
	color: #444444;
	letter-spacing: -1px;
}

.reward-purchase-history-wrap {
	padding-top: 2em;
	display: flex;
	flex-direction: column;
	gap: 0.5em;

	a {
		font-size: 1.1em;
		text-decoration: underline;
		color: #a8a8a8;
		letter-spacing: -1px;
	}

	p {
		font-size: 1em;
		color: #a8a8a8;
		letter-spacing: -1px;
	}
}

.seller-grade-each-wrap {
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding-bottom: 1.5em;
	border-bottom: solid 1px #d1d1d1;

	&.mobile {
		@media (min-width: 768px) {
			padding-bottom: 0;
			border-bottom: none;
		}
	}

	&:last-child {
		border-bottom: none;
	}

	.divider {
		background-color: #ebebeb;
		height: 0.8em;
		min-height: 10px;
	}

	.grade-information-div {
		display: flex;
		flex-direction: column;
		padding: 0 4.5rem;
		@media (max-width: 768px) {
			padding: 0 0.5rem;
		}

		&.mobile {
			display: none;
			@media (max-width: 768px) {
				display: flex;
			}
		}

		.grade-target-text {
			color: #212529;
			font-size: 1.3em;
			letter-spacing: -0.7px;
			line-height: 1.6;
			@media (max-width: 768px) {
				font-size: 1.1em;
				white-space: pre-wrap;
			}

			span {
				color: #117ffa;
				letter-spacing: -0.7px;
				line-height: 1.6;
			}
		}

		.grade-next-month {
			color: #212529;
			font-size: 1.2em;
			letter-spacing: -0.7px;
			line-height: 1.6;
			@media (max-width: 768px) {
				font-size: 1em;
				white-space: pre-wrap;
			}

			span {
				color: #117ffa;
				font-size: 1em;
				letter-spacing: -0.7px;
				line-height: 1.6;
			}
		}
	}

	.seller-grade-benefit {
		padding: 2.5rem 4.5rem 1rem;
		@media (max-width: 768px) {
			padding: 2.5rem 0.5rem 1rem;
		}

		.grade-benefit-graph-list {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding-top: 1.5em;
			gap: 6em;
			@media (max-width: 768px) {
				justify-content: space-around;
				gap: 0;
			}

			.grade-benefit-graph-each {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 6em;
				gap: 6px;
				@media (max-width: 768px) {
					width: 4em;
				}

				img {
					display: none;
				}

				&.hello {
					.grade-benefit-each-bar-graph {
						height: 5em;
						@media (max-width: 768px) {
							height: 3em;
						}
					}
				}

				&.family {
					.grade-benefit-each-bar-graph {
						height: 8em;
						@media (max-width: 768px) {
							height: 6em;
						}
					}
				}

				&.premium {
					.grade-benefit-each-bar-graph {
						height: 14em;
						@media (max-width: 768px) {
							height: 12em;
						}
					}
				}

				.grade-benefit-each-bar-graph {
					background-color: #ecf4fe;
					width: 100%;
					border-radius: 4px;
				}

				p {
					font-size: 1.1em;
					font-weight: normal;
					letter-spacing: -0.7px;
				}

				&.active {
					img {
						position: absolute;
						bottom: 100%;
						display: flex;
						width: 4em;
						height: 4em;
						object-fit: cover;
						padding-bottom: 0.5em;
					}

					&.premium {
						img {
							width: 4.8em;
							height: 4.8em;

							&#crown {
								width: 1.4em;
								height: 1.4em;
								object-fit: contain;
								top: 100%;
							}
						}
					}

					.grade-benefit-each-bar-graph {
						background-color: #117ffa;
					}

					p {
						color: #117ffa;
						font-weight: bold;
					}
				}
			}
		}
	}

	.seller-benefit-list {
		padding: 2.5rem 4.5rem 1rem;
		@media (max-width: 768px) {
			padding: 2.5rem 0.5rem 1rem;
		}
		display: flex;
		flex-direction: column;
		gap: 0.5em;

		h3 {
			margin: 0;
			font-size: 1.3em;
			font-weight: bold;
			color: #117ffa;
			line-height: 1.4;
			letter-spacing: -0.8px;
		}

		.seller-benefit-list-detail {
			display: flex;
			gap: 2%;

			.detail-label {
				width: 13%;
				font-size: 1.1em;
				font-weight: bold;
				color: #212529;
				letter-spacing: -0.7px;
			}

			.detail-body-div {
				display: flex;
				flex-direction: column;
				gap: 0.3em;
				width: 85%;

				.detail-body-text {
					width: 100%;
					font-size: 1.1em;
					color: #212529;
					letter-spacing: -0.7px;
					white-space: pre-wrap;
				}
			}
		}
	}

	.howto-text {
		font-size: 1em;
		color: #9e9e9e;
		line-height: 1.8;
		letter-spacing: -0.6px;

		span {
			font-size: 1em;
			color: #9e9e9e;
			line-height: 1.8;
			letter-spacing: -0.6px;
			font-weight: 600;
		}
	}
}

.seller-user-mypage-div {
	width: 40em;
	margin: auto;

	@media (max-width: 768px) {
		width: 100%;
	}
	display: flex;
	flex-direction: column;
	gap: 1em;

	h1 {
		margin: 0;
		font-size: 1.6em;
		letter-spacing: -0.56px;
		font-weight: bold;

		span {
			color: #117ffa;
			letter-spacing: -0.56px;
			font-weight: bold;
		}
	}

	.mypage-form-body {
		display: flex;
		flex-direction: column;

		border: solid 0.5px #d1d1d1;
		@media (max-width: 768px) {
			gap: 1.5em;
			border: none;
		}
	}

	.mypage-title-div {
		display: flex;
		flex-direction: column;
		gap: 0.5em;

		p {
			padding: 1rem 1.5rem;
			background-color: #ecf4fe;

			font-size: 1.3em;
			font-weight: 600;
			line-height: 1.5;
			letter-spacing: -0.3px;
		}
	}

	.mypage-form-div {
		display: flex;
		flex-direction: row;
		padding: 2em 1em;
		align-items: center;
		border-bottom: solid 0.5px #d1d1d1;

		&:last-child {
			border-bottom: none;
		}

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 0.3em;
			padding: 0;
			align-items: inherit;
			border-bottom: none;
		}

		label {
			width: 20%;
			font-size: 1.1em;
			color: #343a40;
			font-weight: 500;
			@media (max-width: 768px) {
				color: #97a0aa;
				font-size: 1em;
				width: 100%;
			}
		}

		.mypage-form-item {
			max-width: 80%;
			border: solid 0.3px #97a0aa;
			border-radius: 5px;
			padding: 0.8rem 1.2rem;
			font-size: 1.1em;
			@media (max-width: 768px) {
				max-width: 100%;
				width: 100%;
			}

			&:disabled,
			&.disabled {
				background-color: rgba(151, 160, 170, 0.13);
				color: rgba(0, 0, 0, 0.42);
			}

			&::placeholder {
				color: #a8a8a8;
			}

			&.dropdown {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #a8a8a8;

				img {
					width: 1.2em;
					height: 1.2em;
				}

				&.selected {
					color: black;
				}
			}

			.linked-icon {
				width: 1.2em;
				height: 1.2em;
			}

			.linked-text {
				font-size: 1em;
				color: rgba(0, 0, 0, 0.42);
			}

			@media (min-width: 768px) {
				&#linked_account,
				&#name,
				&#phone {
					width: 45%;
				}
				&#bank,
				&#bank_name {
					width: 35%;
				}
				&#account {
					width: 65%;
				}
			}
		}

		.mypage-notice-text {
			font-size: 1em;
			color: #585858;
			line-height: 1.8;
			letter-spacing: -0.6px;
			white-space: pre-wrap;
			width: 100%;
			text-align: left;

			span {
				color: #117ffa;
			}

			@media (max-width: 768px) {
				display: none;
			}

			&.mobile {
				display: none;

				@media (max-width: 768px) {
					text-align: center;
					display: block;
				}
			}
		}
	}

	.mypage-button-group {
		display: flex;
		justify-content: center;
		gap: 0.5em;
		padding-top: 3rem;

		button {
			border: solid 0.5px #117ffa;
			border-radius: 24px;
			padding: 0.5em 3em;
			background-color: #117ffa;
			height: auto;

			span {
				color: white;
				font-size: 1.2em;
				letter-spacing: -0.9px;
				line-height: 1.6;
				font-weight: 600;
			}

			&.cancel {
				background-color: white;
				border: solid 0.5px #8f8f8f;

				span {
					color: #2b2b2b;
				}
			}
		}
	}

	.mypage-logout-button-group {
		display: flex;
		justify-content: center;

		button {
			padding: 0.5rem 2rem;
			font-size: 1em;
			color: #d2d2d2;
			line-height: 1.6;
		}
	}
}

// Recommend.jsx
// -- 배너 --
.recommend-banner-wrap {
	position: relative;
	width: 100%;
	.banner-text {
		position: absolute;
		width: 100%;
		top: 24%;
		left: 50%;
		right: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		@media (min-width: 768px) {
			left: 30%;
			right: unset;
			top: 50%;
		}
		// font
		> p {
			color: #fff;
			line-height: 1.2;
		}
		.small {
			font-weight: 400;
			font-size: 1.55em;
			> span.benefit {
				font-weight: 700;
				color: #fff;
				font-size: 1.12em;
				line-height: 1.25;
				letter-spacing: -1px;
			}
			@media (max-width: 768px) {
				font-size: 1.65em;
			}
			@media (max-width: 300px) {
				font-size: 1.5em;
			}
		}
		.large {
			font-weight: 600;
			font-size: 1.55em;
			@media (max-width: 768px) {
				font-size: 1.65em;
			}
			@media (max-width: 300px) {
				font-size: 1.6em;
			}
		}
	}
}
// -- 하단 내용 --
.recommend-content-wrap {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 20px 1.2em 0;
	@media (min-width: 768px) {
		padding: 30px 0 8em;
		gap: 23px;
	}

	.recommend-code-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		padding: 23px 0 16px;
		@media (min-width: 768px) {
			box-sizing: border-box;
			width: 550px;
			max-width: 100%;
			margin: 0 auto;
			padding: 45px 0 40px;
		}
		p {
			color: #1a1a1a;
			font-weight: 500;

			&.title {
				font-size: 1.15em;
				line-height: 1.8;
				@media (min-width: 768px) {
					font-size: 0.95em;
					line-height: 32px;
				}
			}

			&.referral-code {
				color: #1a1a1a;
				font-weight: 600;
				font-size: 2em;
				line-height: 1.25;
				@media (min-width: 768px) {
					font-size: 1.7em;
					line-height: 32px;
				}
			}
		}

		.recommend-code-copy-div {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 6px;
			width: 100%;
			margin-top: 7px;

			p {
				color: #979797;
				font-weight: 500;
				font-size: 0.95em;
				line-height: 2.6;
				@media (min-width: 768px) {
					font-size: 0.8em;
					line-height: 32px;
					// padding-top: 14px;
				}
			}
		}
	}

	.recommend-list-wrap {
		display: flex;
		flex-direction: column;
		padding: 0 0.8em;
		@media (min-width: 768px) {
			box-sizing: border-box;
			width: 315px;
			max-width: 100%;
			margin: 0 auto;
			padding: 0;
		}

		.title {
			color: #1a1a1a;
			font-weight: 600;
			font-size: 1.25em;
			line-height: 2;
			@media (min-width: 768px) {
				line-height: 32px;
				font-size: 1.06em;
			}
		}

		.recommend-list-content-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				color: #9d9996;
				font-weight: 500;
				font-size: 1.1em;
				line-height: 2.25;
				@media (min-width: 768px) {
					line-height: 32px;
					font-size: 0.9em;
				}
			}
		}
	}
}

.mypage-head-title {
	padding: 3em 0 1.3em;
	font-size: 1.5em;
	line-height: 1;
	font-weight: 700;
}
