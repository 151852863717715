.dashboard-main-div {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.2em;
	@media (max-width: 768px) {
		background: #f3f9ff;
		padding: 1.8em;
		gap: 1.8em;
	}
	.dashboard-division {
		display: flex;
		gap: 2%;

		.dashboard-component {
			&:first-child {
				width: 52%;
			}

			&:last-child {
				width: 46%;
			}
		}
	}
}

.dashboard-component {
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	border: solid 1px #dee2e6;
	padding: 1.5em;
	background-color: white;
	gap: 1em;

	@media (max-width: 768px) {
		padding-top: 2em;

		&:not(&.not-dashboard) {
			border: none;
			border-radius: 15px;
		}
	}

	p,
	h1,
	h2,
	h3,
	h4 {
		margin: 0;
		letter-spacing: -0.3px;
	}

	h1,
	h2,
	h3,
	h4 {
		font-weight: bold;

		span {
			font-weight: bold;
		}
	}

	h1 {
		font-size: 1.8em;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.2em;
		@media (max-width: 768px) {
			font-size: 1.4em;
			font-weight: 600;
			color: #1a1a1a;
			line-height: 1.2;
			letter-spacing: initial;
		}
	}

	h4 {
		font-size: 1.1em;
	}

	p {
		font-size: 1em;
	}

	.light-gray {
		color: #adb5bd;
	}

	.dark-gray {
		color: #868e96;
	}

	.border-right {
		border-right: solid 1px #dee2e6;
	}

	.border-left {
		border-left: solid 1px #dee2e6;
	}

	.border-top {
		border-top: solid 1px #dee2e6;
		&.gold-settlement-button-wrap {
			@media (max-width: 768px) {
				border-top: none;
			}
		}
	}

	.border-bottom {
		border-bottom: solid 1px #dee2e6;
	}

	.dashboard-component-header-wrap {
		display: flex;
		align-items: center;
		> h3 {
			@media (max-width: 768px) {
				font-size: 1.4em;
				font-weight: 600;
				color: #1a1a1a;
			}
		}

		.header-notice-mark {
			width: 16px;
			height: 16px;
			cursor: default;
			background-color: #9d9996;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin-left: 6px;

			@media (max-width: 768px) {
				display: none;
			}
		}
	}

	.dashboard-component-search-date-sign {
		color: #9d9996;
		font-weight: 500;
		font-size: 1.2em;
	}

	.dashboard-component-footer {
		width: calc(100% + 3em);
		margin: 0 -1.5em -1.5em;
		padding: 1em 1.5em;
		border-top: solid 1px #dee2e6;
		display: flex;
		align-items: center;
		gap: 0.5em;
		@media (max-width: 768px) {
			justify-content: center;
		}
		&.no-line {
			border-top: none;
		}
		&.mobile {
			display: none;
			@media (max-width: 768px) {
				display: flex;
				width: 100%;
				padding: 1.8em 1.5em 1rem;
				margin: 0;
			}
		}
	}

	.dashboard-footer-font {
		font-size: 1.1em;
		color: #ced4da;
		@media (max-width: 768px) {
			color: #9d9996;
		}
	}
}

.dashboard-banner-div {
	display: flex;
	align-items: center;
	gap: 1em;
	h2 {
		font-size: 1.3em;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		h2,
		p {
			white-space: pre-wrap;
			text-align: center;
			word-break: keep-all;
		}
		h2 {
			padding-bottom: 0.5em;
		}
		p {
			font-size: 1.1em;
		}
	}
}

.dashboard-component-chart-bottom {
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 2.5em;
		letter-spacing: -2px;
	}

	&.month-sales {
		width: 35%;
		gap: 1em;
	}

	.dashboard-big-price-div {
		display: flex;
		@media (max-width: 768px) {
			flex-direction: column;
		}

		.dashboard-point-with-img-div {
			width: 55%;
			//padding: 1em 0;

			&:first-child {
				border-right: solid 1px #dee2e6;
			}

			&.align-start {
				align-items: flex-start;
			}

			@media (max-width: 768px) {
				width: 100%;
				border-right: none !important;
			}
		}

		.point-list {
			width: 45%;
			@media (max-width: 768px) {
				display: none;
			}
		}
	}
}

.dashboard-point-with-img-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.title-unit {
		color: #1a1a1a;
		font-weight: 500;
		font-size: 1.2em;
	}

	.pre-date-unit {
		color: #d9d9d9;
		font-size: 0.9em;
		margin-left: 2px;

		@media (max-width: 768px) {
			color: #868e96;
		}
	}
}

.point-list-mobile {
	display: none;
	@media (max-width: 768px) {
		display: flex;
		width: 100%;
		border-top: solid 1px #dee2e6;
		padding: 0.5em 0 0;
	}
}

.skeleton-point-price {
	padding-right: 1.2em;
	margin: 0;

	.ant-skeleton-content {
		vertical-align: bottom;
	}

	@media (max-width: 768px) {
		padding-right: 0;
	}
}

.skeleton-empty-list {
	width: 100%;

	.img-skeleton {
		width: 100%;
		height: 100%;
		aspect-ratio: 1;

		&.ant-skeleton-element .ant-skeleton-image-svg {
			display: none;
		}
	}

	.dashboard-product-etc-info-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4px;

		.dashboard-product-etc-info {
			width: 50px;
			min-width: 50px;
			height: calc(0.9em * 1.5715);
		}
	}

	.dashboard-product-name {
		height: calc(1em * 1.4);
		min-width: 80%;
		width: 80%;
	}
}

.my-line-chart-custom {
	z-index: 50;
	canvas {
		width: fit-content;
		max-height: 20rem;
	}
}

.gold-component-wrap {
	position: relative;

	&.not-dashboard {
		gap: 0;
	}

	.help-div {
		color: #117ffa;
		width: 19px;
		height: 19px;
		background-color: #eef6ff;
		border-radius: 50%;
		text-align: center;
		line-height: 19px;
		margin-left: 4px;
		cursor: pointer;
	}

	.gold-info-wrap {
		display: flex;
		flex-direction: column;
		@media (max-width: 768px) {
			padding-bottom: 0 !important;
		}

		.gold-info-div {
			display: flex;
			align-items: center;
			gap: 20px;

			&.not-dashboard {
				// margin-bottom: calc(1em * 1.5);
			}

			.gold-img {
				width: 30px;

				@media (max-width: 768px) {
					width: 22px;
				}
			}

			.gold-info-text-wrap {
				display: flex;
				align-items: baseline;
				gap: 5px;

				.gold-unit {
					font-weight: 600;
					font-size: 1.1em;
					line-height: 1.2;
					color: #1a1a1a;
				}
			}

			.gold-info {
				color: #117ffa;
				font-size: 2.5em;
			}
			.calculate-gold-info {
				font-size: 3em;
				font-weight: 600;
			}

			b {
				font-size: 1.3em;
			}
		}

		.gold-info-desc {
			color: #d9d9d9;
			font-size: 1.15em;
			font-weight: 500;

			@media (max-width: 768px) {
				line-height: 1.25;
			}

			span {
				color: #117ffa;
				font-weight: 500;

				@media (max-width: 768px) {
					line-height: 1.25;
				}
			}
		}
	}

	.gold-info-skeleton {
		.ant-skeleton-content {
			.ant-skeleton-paragraph {
				margin-bottom: 0;
				li:first-child {
					height: calc(3em * 1.5);
				}

				li:not(:first-child) {
					height: calc(1.15em * 1.35);
				}

				li:last-child {
					margin-bottom: 1em;

					@media (max-width: 768px) {
						margin-bottom: 0;
					}
				}

				li + li {
					margin-top: 2px;

					@media (max-width: 768px) {
						margin-top: 11px;
					}
				}
			}
		}
	}

	.gold-settlement-button-wrap {
		padding-top: 1.5em;
		display: flex;
		justify-content: center;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
			gap: 20px;
			padding-top: 8px;
		}

		.gold-settlement-button {
			border-radius: 5px;
			border: none;
			padding: 1.1em 4%;
			height: auto;

			@media (max-width: 768px) {
				width: 100%;
				min-width: 110px;
				padding: 12px 0;
			}

			span {
				color: #ffffff;
				font-size: 1.1em;
				font-weight: 500;

				@media (max-width: 768px) {
					font-size: 1.2em;
				}
			}

			&.active {
				background-color: #117ffa;
			}

			&.disabled {
				background-color: #d9d9d9;
			}

			&.settlement-request {
				background-color: #eef6ff;

				span {
					color: #117ffa;
				}
			}
		}

		.gold-settlement-button-skeleton {
			height: calc(23px + 1.8em);
			width: calc(53px + 8vw);

			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}

	h3 {
		font-weight: 600;
		font-size: 1.1em;
		line-height: 1.2;
		color: #1a1a1a;

		.help-div {
			display: inline-block;
			color: #117ffa;
			font-weight: 500;
			//font-size: 0.9em;
			//width: 19px;
			//height: 19px;
			//background-color: #eef6ff;
			//border-radius: 50%;
			//text-align: center;
			//line-height: 1.2;
			margin-left: 2px;
			cursor: pointer;
		}
	}
}

.notice-component-wrap {
	@media (max-width: 768px) {
		gap: 8px;
		padding: 2em 0 1.5em;
	}

	.dashboard-component-header {
		display: flex;
		flex-direction: column;
		gap: 6px;

		@media (max-width: 768px) {
			padding: 0 1.5em;
			gap: 4px;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		background-color: #d9d9d9;
	}

	.notice-tab-bar-wrap {
		.ant-tabs-nav {
			@media (max-width: 768px) {
				margin-bottom: 7px;
			}
		}
	}

	.dashboard-component-footer {
		&.mobile {
			@media (max-width: 768px) {
				padding: 0;
			}
		}
	}
}

.read-more {
	position: absolute;
	top: 1.5em;
	right: 1.5em;
	cursor: pointer;
	color: #9d9996;
	font-weight: 400;
	&.product,
	&.faq,
	&.notice {
		@media (max-width: 768px) {
			display: none;
		}
	}
	&.dashboard-gold-info {
		position: initial;
	}
}

.modal-wrap {
	//top: 200px;
	.modal-title {
		font-size: 1.3em;
		font-weight: 500;
		color: #000000;
		text-align: center;
		padding-top: 2em;
		margin-bottom: 2em;

		@media (max-width: 768px) {
			padding-top: 2em;
			margin-bottom: 1em;
			color: #1a1a1a;
			font-weight: 600;
			font-size: 1.15em;
			line-height: 1.5;
		}
		span {
			font-weight: 700;
		}
	}
	.modal-title-sub {
		font-size: 14;
		font-weight: 500;
		color: #117ffa;
		text-align: center;
		margin-bottom: 1.6em;
	}
	.modal-button-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		margin-bottom: -1em;
		@media (max-width: 768px) {
			flex-direction: column;
		}
		.modal-button-success {
			background-color: #117ffa;
			font-size: 1.2em;
			color: #ffffff;
			width: 40%;
			text-align: center;
			padding: 0.5em 0;
			border-radius: 6px;
			cursor: pointer;
			font-weight: 600;

			@media (max-width: 768px) {
				width: 100%;
				border-radius: 10px;
				line-height: 1.18;
				padding: 0.8em 0;
			}
		}
		.modal-button-cancel {
			font-size: 1.2em;
			color: #d9d9d9;
			width: 40%;
			text-align: center;
			padding: 0.5em 0;
			border-radius: 6px;
			border: 1px solid #d9d9d9;
			cursor: pointer;
			font-weight: 600;

			@media (max-width: 768px) {
				width: 100%;
				border-radius: 10px;
				line-height: 1.18;
				padding: 0.8em 0;
				border: none;
				color: #979797;
			}
		}
	}
	.modal-change-cash-wrap {
		border-bottom: 1px solid #d9d9d9;
		padding: 2.4em 0 3em 0;
		margin-bottom: 2em;
		.modal-has-cash-text {
			font-size: 16px;
			font-weight: 500;
			color: #000000;
			margin-bottom: 0.5em;
		}
		.modal-input-wrap {
			display: flex;
			justify-content: space-between;
			height: 50px;
			gap: 16px;
			.modal-input {
				font-size: 16px;
				font-weight: 500;
				padding-left: 22px;
				height: 100%;
			}
			.modal-cash-text {
				font-size: 18px;
				color: #117ffa;
				font-weight: 600;
				position: absolute;
				top: 10px;
				right: 26px;
				> span {
					color: #000;
					font-weight: 600;
				}
			}
			.modal-input-button {
				background-color: #eef6ff;
				color: #117ffa;
				padding: 15px 27px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 20%;
				font-size: 16px;
				font-weight: 600;
				border-radius: 6px;
				cursor: pointer;
				@media (min-width: 768px) {
					width: 100%;
				}
				&.modal-input-button-all {
					background-color: #ffffff;
					border: 1px solid #117ffa;
					width: 21%;
					@media (min-width: 768px) {
						width: 100%;
					}
				}
			}
		}
		.modal-sub-desc-text {
			color: #d9d9d9;
			font-size: 14px;
			font-weight: 400;
			margin-top: 0.3em;
			margin-bottom: 20px;
		}
	}
}

.dashboard-product-link-info-wrap {
	h3,
	p {
		color: #ffffff;
	}
}

.productLink.dropAreaRow {
	@media (max-width: 768px) {
		display: none;
	}
}

// 랜딩페이지 이벤트 모달
.landing-event-modal-wrap {
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
}

.landing-event-modal-wrap .content-wrap {
	background: #fff;
	width: calc(100% - 3.5em);
	// max-width: ;
	position: absolute;
	left: 50%;
	right: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	gap: 2.1em;
	padding: 4.5em 18px 25px 18px;
	border-radius: 16px;

	img.icon {
		width: 100%;
		display: block;
		margin: 0 auto;
	}

	.text-wrap {
		text-align: center;
		white-space: pre-wrap;
		.title {
			font-size: 2.1em;
			color: #1a1a1a;
			font-weight: 600;
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			line-height: 1.25;
			letter-spacing: -1px;

			&.underline {
				&::after {
					display: block;
					position: absolute;
					content: "";
					width: 100%;
					height: 11px;
					padding: 0 30px;
					left: 0;
					bottom: 5px;
					background: #00a4ff;
					opacity: 0.35;
				}
			}
			@media (max-width: 320px) {
				font-size: 1.8em;
			}
		}
		.desc {
			font-size: 1.2em;
			color: #1a1a1a;
			font-weight: 400;
			margin-top: 0.6em;
			line-height: 1.1;
			letter-spacing: -0.8px;
			> span {
				color: #00a4ff;
				font-weight: 600;
			}
			@media (max-width: 300px) {
				font-size: 1.1em;
			}
		}
		.sub-desc {
			font-size: 0.9em;
			color: #555;
			font-weight: 400;
			padding-top: 3em;
		}
	}
	.go-event-link-btn {
		width: 100%;
		background: #117ffa;
		color: #fff;
		padding: 13px 0;
		width: 100%;
		font-size: 1.15em;
		border-radius: 8px;
	}
	.cancel-event-link-btn {
		width: 100%;
		color: #d9d9d9;
		background: #fff;
		padding: 13px 0;
		width: 100%;
		font-size: 1.15em;
		border-radius: 8px;
	}

	.close-modal-btn {
		position: absolute;
		right: 0.5em;
		top: 0.2em;
		padding: 1em;
	}
}
