.channel-home {
	padding: 0;
	background-color: white;
	flex-direction: column !important;
	margin-top: 5em !important;

	@media (max-width: 768px) {
		margin-top: 5em !important;
	}
}

.home-body {
	padding: 3em 10em;
	display: flex;
	flex-direction: column;
	gap: 2em;
	@media (max-width: 768px) {
		padding: 2em 1.2em !important;
		overflow: hidden;
		gap: 0;
	}

	h2 {
		font-size: 1.8em;
		font-weight: bold;
		white-space: pre-wrap;
		text-align: left;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5em;
			text-align: center;
			word-break: keep-all;
			line-height: 1.2em;
		}
	}

	.home-images-group {
		width: 100%;
		display: flex;
		gap: 2%;
		//padding: 0 0 3em;

		.image-each {
			width: 23.5%;
			max-width: 23.5%;
			//overflow: hidden;
			flex-grow: 1;
			position: relative;
			cursor: pointer;
			//height: 13em;
			height: 100%;
			border-radius: 5px;
			display: flex;
			//align-items: flex-end;

			img.home-image {
				width: 100%;
				height: auto;
				border-radius: 5px;
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			}

			.image-title {
				display: flex;
				//flex-direction: column;
				position: absolute;
				top: 1em;
				left: 1em;
				gap: 0.2em;
				@media screen and (max-width: 768px) {
					top: 1.4em;
					left: 1.4em;
				}
				@media screen and (max-width: 375px) {
					top: 1em;
					left: 1em;
				}

				p {
					font-size: 1.8em;
					font-weight: 600;
					color: white;
					margin: 0;
					@media screen and (max-width: 768px) {
						font-size: 1.4em;
					}
					@media screen and (max-width: 375px) {
						font-size: 1em;
					}
				}

				img {
					width: 2em;
					height: auto;
					@media screen and (max-width: 375px) {
						width: 1.5em;
					}
				}
			}
		}
	}

	.youtube-link-div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 6.5em;
		padding: 0 2.5em;
		margin-bottom: 2em;
		border-radius: 5px;
		box-shadow: 0 3px 10px 0 rgba(184, 208, 235, 0.6);
		background-color: #94c7ff;
		cursor: pointer;
		@media screen and (max-width: 768px) {
			padding: 0 1em;
		}

		.youtube-link-title-div {
			display: flex;
			align-items: center;
			gap: 1em;

			img {
				width: 3.4em;
				height: auto;
				@media screen and (max-width: 1024px) {
					width: 4.8em;
				}
				@media screen and (max-width: 768px) {
					width: 3.8em;
				}
			}
		}

		p {
			font-size: 1.9em;
			font-weight: bold;
			color: white;
			margin: 0;
			word-break: keep-all;
			@media screen and (max-width: 768px) {
				font-size: 1.4em;
			}
			@media screen and (max-width: 375px) {
				font-size: 1em;
			}
		}

		.arrow-icon {
			width: 2.5em;
			height: auto;
			margin-left: 0.5em;
			@media screen and (max-width: 375px) {
				width: 2em;
			}
		}
	}
}

.login-div {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 1em;
	position: relative;

	.recently-login-icon {
		display: none;
		position: absolute;
		width: 11em;
		height: auto;
		top: -1em;
		right: 0;
		z-index: 99;
		@media (max-width: 768px) {
			width: 9em;
			top: -0.5em;
		}

		&.sns {
			top: -2.5em;
			@media (max-width: 768px) {
				width: 9em;
				top: -1.5em;
			}
		}
	}

	div.login-input-div {
		display: flex;
		flex-direction: column;
		gap: 0.3em;
	}
	p.login-title {
		font-size: 1.1em;
		font-weight: bold;
		letter-spacing: -1.2px;
		@media (max-width: 768px) {
			display: none;
		}
	}
	p.login-title-normal {
		font-size: 1.1em;
		font-weight: 500;
		letter-spacing: -1.2px;
	}

	input {
		border: none;
		border-bottom: solid 1px #ced4da;
		height: auto;
		padding: 0.6em 1em;
		@media (max-width: 768px) {
			border-bottom: solid 1px #ced4da;
		}

		&::placeholder {
			color: #adb5bd;
		}

		&:focus {
			box-shadow: none;
			// border: none;
			@media (max-width: 768px) {
				box-shadow: none;
			}
		}
	}

	.save-info-button-group {
		gap: 0.8em;

		button {
			border: none;
			box-shadow: none;
			outline: none;
			padding: 0;
			gap: 5px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			img {
				height: 50%;
			}
		}
	}

	.black-full-button {
		border-radius: 5px;
		background-color: #292929;
		height: auto;
		padding: 0.6em;

		span {
			color: white;
			font-weight: 500;
			font-size: 1.1em;
		}
	}

	.signup-user-phone-button {
		border-radius: 8px;
		width: fit-content;
		padding: 3px 0.8em;
		border: solid 1px #ff3131;
		color: #ff3131;
		font-weight: 500;
		font-size: 1em;
		letter-spacing: -1px;
		margin-left: auto;
		@media (max-width: 768px) {
			display: none;
		}
	}
	.blue-login-button {
		border-radius: 5px;
		background-color: #117ffa;
		height: auto;
		padding: 0.7em;

		span {
			color: white;
			font-weight: 500;
			font-size: 1.1em;
		}

		&:disabled {
			background: #d9d9d9;
			color: #fff;
		}
	}
	.white-signup-button {
		border-radius: 5px;
		background-color: #fff;
		height: auto;
		padding: 0.7em;
		border: 1px #117ffa solid;
		span {
			color: #117ffa;
			font-weight: 500;
			font-size: 1.1em;
		}
	}

	p.signup-login-footer-text {
		color: #2a2a2a;
		letter-spacing: -1px;
		white-space: pre-wrap;
		text-align: center;
		@media (max-width: 768px) {
			display: none;
		}
	}

	.button-group-full {
		width: 100%;
		padding: 0.8em 0;
		border-bottom: solid 1px #ced4da;

		button {
			width: 100%;
			flex-grow: 1;
			height: auto;
			padding: 0;
			box-shadow: none;
			border-top: none;
			border-bottom: none;
			border-right: solid 1px #ced4da;
			border-color: #ced4da !important;

			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}

			span {
				width: 100%;
				margin: auto;
				font-size: 1em;
				@media (max-width: 768px) {
					font-size: 0.9em;
				}
			}
		}
	}

	.login-signup-div {
		align-items: center;
		justify-content: center;
		gap: 0.5em;
		padding: 0.5em 0;

		p {
			color: #fe2b4b;
			font-size: 0.9em;
		}

		a {
			border: solid 1px #fe2b4b;
			border-radius: 5px;
			padding: 0 0.5em;
			color: #fe2b4b;
			font-size: 0.9em;
		}
	}
}

.login-page-div {
	width: 60%;

	@media (max-width: 768px) {
		width: 100%;
	}

	&.mall-login-page-div {
		background-color: white;
		padding: 2.5em 2em;
		border-radius: 12px;
		width: 45%;
		max-width: 420px;
		min-width: 24em;

		@media (max-width: 768px) {
			min-width: 300px;
		}
	}

	&.mall-login-page-div.find-pwd-div {
		background-color: white;
		padding: 2.5em 2em;
		border-radius: 12px;
		width: 50%;
		// max-width: 540px;
		min-width: 540px;
		border: #d9d9d9 1px solid;

		.login-title {
			font-size: 1em;
			font-weight: 400;
		}
	}
}
