.layout {
	@media (max-width: 768px) {
		padding: 4.5em 0rem;

		&.inquiry {
			margin-top: 0;
		}
	}
}
.layout.user-layout {
	@media (min-width: 768px) {
		max-width: 1200px;
		margin: auto !important;
		width: 100%;
		// margin: 5em auto;
		padding: 8em 3em;
	}
}
.calculate-head-title {
	max-width: 1180px;
	margin: 0 auto;
	color: #000;
	font-size: 1.5em;
	font-weight: 700;
	margin-top: 2.5em;
	line-height: 1s;
	padding: 3em 0 1.3em;
}

.calculate-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 0;
	// padding-top: 6em;
	// padding-bottom: 0.7em;
	background: #fff;
	padding: 5.4em 1.8em 2em;
	border-bottom: 10px solid #f8f9fa;
	@media (min-width: 768px) {
		max-width: 1180px;
		margin: 0 auto;
		padding: 2.3em 4em;
		align-items: flex-start;
		border: 1px solid #dee2e6;
		flex-direction: row;
	}
	> img {
		position: absolute;
		top: 20px;
		left: 20px;
	}
	.calculate-main-title {
		display: flex;
		color: #ffffff;
		font-weight: 700;
		width: 100%;
		text-align: left;
		justify-content: space-between;
		align-items: flex-end;
		font-size: 1.85em;
		@media (min-width: 768px) {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 20px;
			border-right: 1px solid #dee2e6;
			padding: 10px 0;
		}
		> div > p {
			font-weight: 600;
			line-height: 1;
			@media (min-width: 768px) {
				font-weight: 600;
				line-height: 1.2;
			}
		}
		> div > p > span {
			font-weight: 700;
			@media (min-width: 768px) {
				font-weight: 700;
			}
		}

		.btn-user-edit {
			color: #979797;
			font-size: 0.55em;
			font-weight: 600;
			@media (min-width: 768px) {
				color: #979797;
				font-size: 0.55em;
				font-weight: 600;
				margin-top: 2.1em;
			}
		}
	}

	.gold-box {
		width: 100%;
		background: #117ffa;
		border-radius: 12px;
		margin-top: 1.5em;
		padding: 2em 1.6em;
		@media (min-width: 768px) {
			background: #fff;
			margin-top: 0;
			padding: 10px 0 10px 3.8em;
		}
		.desc {
			color: #fff;
			font-size: 1.1em;
			font-weight: 600;
			line-height: 1.2;
			@media (min-width: 768px) {
				color: #000;
				font-size: 1.55em;
				font-weight: 600;
				padding-bottom: 8px;
			}
		}
		.gold-count-box {
			display: flex;
			align-items: center;
			gap: 5px;
			margin-top: 0.5em;
			@media (min-width: 768px) {
				margin-top: 8px;
				gap: 24px;
			}
		}

		.gold-count-box img {
			@media (max-width: 768px) {
				width: 1.85em;
			}
		}

		.gold-count-box p {
			color: #fff;
			line-height: 1;
			font-size: 1em;
			font-weight: 600;
			@media (min-width: 768px) {
				color: #000;
				font-size: 1.4em;
			}
		}
		.gold-count-box p .gold {
			color: #fff;
			letter-spacing: -0.5px;
			line-height: 1;
			font-size: 2.3em;
			font-weight: 600;
			@media (min-width: 768px) {
				color: #117ffa;
				font-size: 2em;
				letter-spacing: -2px;
			}
		}
	}

	.calculate-sub-title {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 38px;
		@media (max-width: 768px) {
			font-size: 1.2rem;
		}
	}
	.calculate-referral-code {
		color: #ffffff;
		margin-bottom: 15px;
		@media (max-width: 768px) {
			display: none;
		}
	}
	.calculate-category-wrap {
		display: flex;
		justify-content: center;
		@media (max-width: 768px) {
			display: none;
		}
		.calculate-category {
			color: #ffffff;
			font-size: 16px;
			font-weight: 500;
			padding: 15px 0px;
			width: 162px;
			text-align: center;
			cursor: pointer;
			&.calculate-active-category {
				color: #117ffa;
				background-color: #ffffff;
				font-weight: 700;
				border-radius: 6px;
			}
		}
	}
}

.main-wrap {
	&.caculate {
		margin-top: 0 !important;
		height: 100%;
		padding: 0 1.85em !important;
		@media (min-width: 768px) {
			margin-top: 1.8em !important;
			padding: 0 !important;
		}
	}
	.pc-cont {
		display: block;
		@media (max-width: 768px) {
			display: none;
		}
	}
	.mobile-cont .calculate-category-wrap {
		display: flex;
		flex-direction: column;
		@media (min-width: 768px) {
			flex-direction: row;
			gap: 8px;
		}
		.calculate-category-item-wrap {
			padding: 1.75em 0.4em;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media (min-width: 768px) {
				background: #fff;
				width: calc(100% / 3);
				border-radius: 6px;
				border: 1px solid #dee2e6;
				padding: 2em 2.2em;
			}
		}
		.main-font {
			font-weight: 700;
			color: #000000;
			font-size: 1.8rem;
			text-decoration: none;
			@media (min-width: 768px) {
				font-size: 1.15em;
			}
		}
		.sub-font {
			color: #000000;
			font-size: 1.6rem;
			text-decoration: none;
		}
	}
}

.bottom-btn-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0 1.5em;
	@media (min-width: 768px) {
		padding: 0 0 4em;
	}
	> span {
		color: #d9d9d9;
	}
	> button {
		color: #d9d9d9;
		font-size: 1em;
		@media (min-width: 768px) {
			background: #f8f9fa;
			font-size: 1.05em;
		}
	}
}

.calculate-dashboard-component {
	// gap: 0;
	// padding: 74px 58px 20px 58px;
	// display: flex;
	// flex-direction: column;
	// border-radius: 6px;
	// border: solid 1px #dee2e6;
	// background-color: white;

	display: flex;
	flex-direction: column;
	border-radius: 6px;
	border: solid 1px #dee2e6;
	padding: 1.5em;
	background-color: white;
	gap: 1em;

	.calculate-dashboard-component-title {
		font-size: 20px;
		font-weight: 600;
	}
	.calculate-dashboard-questionMark {
		width: 19px;
		height: 19px;
		background-color: #eef6ff;
		color: #117ffa;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 19px;
		margin-left: 4px;
	}
}
.calculate-info-skeleton {
	padding: 10px;
}

.calculate-point-wrapper {
	margin-bottom: 100px;
	max-width: 1200px;
	// margin: auto !important;
	width: 100%;
	.calculate-point-wrap {
		border: 1px solid #dee2e6;
		padding: 4em;
		.calculate-point-title-wrap {
			display: flex;
			align-items: center;
			gap: 16px;
			margin-bottom: 3em;
			.calculate-point-title {
				font-size: 20px;
				font-weight: 600;
				color: #000000;
			}
			.calculate-point-sub-title {
				font-size: 16px;
				font-weight: 500;
				color: #d9d9d9;
			}
		}
		.calculate-graph-wrap {
			.calculate-graph-icon {
				position: absolute;
				top: -60px;
			}
			.calculate-graph-dot-wrap {
				margin: 6em 2em 1em;
				border: 2px solid rgba(0, 0, 0, 0.1);
				position: relative;

				.calculate-graph-dot {
					width: 10px;
					height: 10px;
					background-color: #117ffa;
					border-radius: 10px;
					position: absolute;
					top: -5px;
				}

				.calculate-graph-out-dot {
					width: 15px;
					height: 15px;
					border: 1px solid #117ffa;
					border-radius: 50%;
					background-color: transparent;
					position: absolute;
					top: -7.5px;
				}
			}
			.calculate-graph-text-wrap {
				margin: 1em -0.6em 0 1.6em;
				display: flex;
				position: relative;
				justify-content: space-between;

				.calculate-graph-text {
					font-size: 21px;
					color: #000000;
					font-weight: 600;

					&.center {
						margin-left: -10px;
						position: absolute;
						left: 0;
						right: 0;
						text-align: center;
					}
				}
			}
		}
	}
}

.calculate-inquiry-tab-bar-wrap {
	.ant-tabs-nav {
		margin: 0 1.2em;
		@media (min-width: 768px) {
			margin: 0;
		}
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				width: 100%;
				@media (min-width: 768px) {
					margin-bottom: 3.5em !important;
				}

				.ant-tabs-tab {
					width: calc(50vw - 1.2em);
					padding: 0.75em 0.8em !important;
					justify-content: center;
					font-weight: 600;
					font-size: 1em;
					line-height: 1.3;
					@media (min-width: 768px) {
						width: 117px;
						font-size: 1.2em;
						padding: 1em 2.5em !important;
					}
				}
			}
		}

		&::before {
			border-bottom: none;
		}
	}
}

.calculate-inquiry-category-wrap {
	border-bottom: 1px solid #f4f4f4;
	display: flex;
	padding-left: 10px;
	margin-bottom: 5em;

	.calculate-inquiry-category {
		font-size: 18px;
		font-weight: 500;
		color: #1a1a1a;
		padding: 20px 26px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		&.active {
			font-weight: 700;
			border-bottom: 6px solid #117ffa;
			padding-bottom: 14px;
		}
	}
}
.calculate-inquiry-title {
	font-size: 24px;
	font-weight: 600;
	color: #1a1a1a;
	margin: 1em 0;

	@media (max-width: 768px) {
		padding: 0 2rem;
		font-weight: 600;
		font-size: 1.4em;
		margin: 0.55em 0 0.8em;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.calculate-inquiry-table {
	// border-top: 2px solid #1a1a1a;
	// border-bottom: 2px solid #1a1a1a;
	width: 100%;
	//height: 750px;
	margin-bottom: 100px;
	@media (max-width: 768px) {
		margin-bottom: 85px;
		border-top: 2px solid #1a1a1a;
		border-bottom: 2px solid #1a1a1a;
		border: none;
		margin-bottom: 0;
		padding: 0 2rem;
	}
	&.user-edit {
		@media (max-width: 768px) {
			padding-bottom: 8em;
		}
	}
	&.add-inquiry {
		@media (min-width: 768px) {
			border-top: 2px solid #1a1a1a;
			border-bottom: 2px solid #1a1a1a;
		}
	}
	table {
		width: 100%;
		table-layout: fixed;
		@media (min-width: 768px) {
			border-top: 2px solid #1a1a1a;
			border-bottom: 2px solid #1a1a1a;
		}
		@media (max-width: 768px) {
			margin-bottom: 4.5rem;
		}

		&.no-data {
			@media (max-width: 768px) {
				margin-bottom: 0;
			}
		}

		thead {
			tr {
				border-bottom: 2px solid #1a1a1a;
				@media (min-width: 768px) {
					border-bottom: 1px solid #1a1a1a;
				}
				th {
					width: 33.33%;
					padding: 14px 0;
					font-size: 18px;
					color: #1a1a1a;
					font-weight: 600;
					@media (min-width: 768px) {
						padding: 20px 0;
						font-size: 1.1em;
					}
				}
			}
			@media (max-width: 768px) {
				display: none;
			}
		}

		tbody {
			tr {
				cursor: pointer;
				@media (max-width: 768px) {
					display: flex;
					flex-direction: column;
					width: 100%;
					position: relative;
					border-bottom: 1px solid #d9d9d9;
					padding: 2em 1.6em;
				}

				&.calculate-inquiry-table-noData-row {
					@media (max-width: 768px) {
						border-bottom: none;
					}
				}

				&.inquiry {
					@media (max-width: 768px) {
						padding: 1.8em 1.6em;
					}
				}

				td {
					border-bottom: 1px solid #d9d9d9;
					text-align: center;
					width: 33.33%;
					padding: 12px 0;
					font-size: 18px;
					color: #1a1a1a;
					font-weight: 500;
					@media (max-width: 768px) {
						position: relative;
						border-bottom: none;
						width: calc(100% - 100px);
						text-align: left;
						padding: 3px 0;
					}
					&.td-status {
						&.complete {
							@media (max-width: 768px) {
								color: #117ffa;
								border: 1px solid #117ffa;
							}
						}
						@media (max-width: 768px) {
							font-size: 1.4rem;
							font-weight: 500;
							width: fit-content;
							box-sizing: border-box;
							border: 1px solid #d9d9d9;
							color: #d9d9d9;
							padding: 0.3em 0.5em;
							border-radius: 6px;
							position: absolute;
							right: 2rem;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					&.td-content {
						@media (max-width: 768px) {
							font-size: 1.3em;
							font-weight: 600;
							line-height: 1.25;
						}
						.pc {
							display: block;
							@media (max-width: 768px) {
								display: none;
							}
						}
						.mobile {
							display: none;
							@media (max-width: 768px) {
								display: block;
							}
						}
					}

					&.td-date {
						@media (max-width: 768px) {
							font-size: 1.4rem;
							color: #c8c8c8;
						}
					}
				}
				.calculate-inquiry-table-noData {
					color: #d9d9d9;
					font-weight: 600;
					font-size: 24px;
					border: 0;
					margin-top: 50%;
					height: 500px;

					@media (max-width: 768px) {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 1.2em;
						//height: 0;
						margin-top: 0;
						width: 100%;
						//padding: 4em 2em 6em;
					}
				}
			}

			tr.detail-area,
			tr.detail-area > div {
				display: none;
				@media (max-width: 768px) {
					display: block;
					background: #f6f6f6;
				}
			}
			tr.detail-area {
				strong {
					@media (max-width: 768px) {
						color: #117ffa;
						font-weight: 700;
						font-size: 2.4rem;
						line-height: 1;
					}
				}

				div.content {
					@media (max-width: 768px) {
						color: #000;
					}

					p {
						@media (max-width: 768px) {
							padding-top: 12px;
							font-weight: 500;
							font-size: 1em;
						}
					}
				}

				div.answer {
					@media (max-width: 768px) {
						margin: 2.5rem 0 0;
					}

					p {
						@media (max-width: 768px) {
							padding-top: 12px;
							font-weight: 500;
							font-size: 1em;
						}
					}
				}
			}
		}
	}
	.calculate-inquiry-add-wrapper {
		.calculate-inquiry-add-wrap {
			display: flex;
			padding: 3.2em 5.6em 3.2em 5.6em;
			height: 100px;
			border-bottom: 1px solid #d9d9d9;
			align-items: center;
			&.add-inquiry {
				@media (min-width: 768px) {
					align-items: center;
					padding: 2.8em 5.8em;
				}
			}

			@media (max-width: 768px) {
				padding-left: 0;
				padding: 0;
				flex-direction: column;
				align-items: flex-start;
				border-bottom: none;
				gap: 0.4em;
				height: auto;
				margin-bottom: 15px;
			}
			.calculate-inquiry-add-wrap-title {
				font-size: 18px;
				color: #555555;
				font-weight: 600;
				//width: 30%;
				min-width: 18%;
				max-width: 20%;

				@media (max-width: 768px) {
					display: flex;
					align-items: center;
					font-size: 1.4rem;
					min-width: unset;
					max-width: unset;
					width: 100%;
					display: flex;
					align-items: center;
					//width: 25%;
				}
				span {
					color: #117ffa;
					font-weight: 700;
					font-size: 22px;
					margin-left: 10px;
					@media (max-width: 768px) {
						margin-top: 4px;
						margin-left: 5px;
						font-size: 16px;
					}
				}
			}
			.input-div-button {
				background-color: #eef6ff;
				font-size: 16px;
				color: #117ffa;
				font-weight: 600;
				// padding: 14px 26px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 110px;
				border-radius: 6px;
				cursor: pointer;
				height: 50px;
			}
			.wrap-input {
				@media (max-width: 768px) {
					width: 100%;
				}

				&.user-edit {
					input {
						@media (max-width: 768px) {
							font-weight: 500;
							font-size: 1.25em;
							line-height: 1.2;
						}
					}

					&.personal-number {
						input {
							@media (max-width: 768px) {
								text-align: center;
							}
						}
					}
				}

				&.inquiry {
					input {
						@media (max-width: 768px) {
							font-weight: 500;
							font-size: 1.15em;
							line-height: 1.2;
						}

						&:placeholder {
							font-weight: 400;
						}
					}
				}
			}
			.custom-text-input {
				@media (max-width: 768px) {
					font-weight: 500;
					font-size: 1.25em;
					line-height: 1.2;
				}
			}
			input {
				padding: 14px;
				width: 500px;
				max-width: 100%;
				border: 1px solid #d9d9d9;
				font-size: 18px;
				color: #1a1a1a;
				font-weight: 600;
				height: 50px;
				border-radius: 5px;
				@media (max-width: 768px) {
					width: 100%;
					font-size: 1.1em;
					font-weight: 400;
				}

				&::placeholder {
					font-weight: 400;
					@media (max-width: 768px) {
						font-size: 1.1em;
					}
				}

				&:read-only {
					background: #f6f6f6;
					// @media (max-width: 768px) {
					// }
				}
			}
			select {
				border: 1px solid #d9d9d9;
				width: 500px;
				padding: 14px;
			}
		}
		.calculate-inquiry-add-wrap-textarea {
			padding-left: 80px;

			@media (max-width: 768px) {
				padding-left: 0;
			}

			textarea {
				outline: none;
				border: none;
				resize: none;
				width: 100%;
				padding: 10px;
				font-size: 18px;
				color: #1a1a1a;
				font-weight: 600;
				border-radius: 5px;

				@media (max-width: 768px) {
					font-weight: 500;
					font-size: 1.15em;
					line-height: 1.2;
					border: 1px solid #d9d9d9;
					padding: 14px;
				}
			}

			textarea::placeholder {
				color: #d9d9d9;
			}
		}
	}
}

.calculate-inquiry-desc-text {
	font-size: 16px;
	font-weight: 500;
	color: #1a1a1a;
	margin-bottom: 55px;
	text-align: center;
	@media (max-width: 768px) {
		position: relative;
		font-size: 1em;
		font-weight: 400;
		margin-bottom: 0.5em;

		&.star {
			padding-left: 10px;
		}
		&.star::after {
			display: block;
			content: "*";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
	span {
		font-weight: 500;
		color: #117ffa;
	}
}

.calculate-inquiry-button-wrap {
	display: flex;
	justify-content: center;
	gap: 16px;
	margin-bottom: 60px;
	@media (max-width: 768px) {
		padding: 0 2rem;
		margin: 1.5em 0 15px;
		flex-direction: column;
		width: 100%;
		gap: 0;
	}

	&.add-inquiry {
		@media (min-width: 768px) {
			flex-direction: row-reverse;
		}
	}
	&.user-edit {
		@media (max-width: 768px) {
			position: fixed;
			bottom: 0;
			right: 0;
			left: 0;
			background: #fff;
			margin: 0;
			padding: 2rem 2rem 0;
		}
	}
	.calculate-inquiry-cancel-button {
		padding: 15px 0;
		border-radius: 6px;
		width: 124px;
		height: auto;
		border-color: #d9d9d9;
		color: #979797;
		@media (max-width: 768px) {
			width: 100%;
			border: none;
			box-shadow: none;
		}

		&:hover,
		&:active,
		&:focus {
			color: #117ffa;
			border-color: #117ffa;

			span {
				color: #117ffa;
				@media (max-width: 768px) {
					font-size: 1.6rem;
				}
			}
		}

		span {
			font-size: 18px;
			text-align: center;
			font-weight: 500;
			color: #d9d9d9;
			font-size: 1.1em;
			@media (max-width: 768px) {
				font-size: 1.6rem;
			}
		}
	}

	.calculate-inquiry-write-button {
		padding: 15px 0;
		border-radius: 6px;
		width: 124px;
		height: auto;
		background: #d9d9d9;
		@media (max-width: 768px) {
			width: 100%;
			border: none;
		}

		&.ant-btn-primary:not([disabled]) {
			background: #117ffa;
			border-color: #117ffa;

			span {
				color: #fff;
				font-size: 1.1em;
				@media (max-width: 768px) {
					font-size: 1.6rem;
					color: #fff;
				}
			}
		}

		span {
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			text-align: center;
			@media (max-width: 768px) {
				font-size: 1.6rem;
			}
		}
	}
}

.red {
	border: 1px solid red;
}

// 내 정보 수정 추가 css
.user-edit-tab-wrap {
	display: flex;
	margin-bottom: 10px;
	button {
		display: block;
		max-width: 117px;
		color: #1a1a1a;
		font-size: 1.05em;
		font-weight: 400;
		padding: 8px 2em;
		&.active {
			border-bottom: 4px solid #1a1a1a;
			font-weight: 700;
		}
	}
}

// 새로운 table
.inquiry-detail-box-title {
	font-size: 1.4em;
	color: #171717;
	font-weight: 700;
	padding-bottom: 13px;
}
table.inquiry-detail-box {
	width: 100%;
	margin-bottom: 5em;
	tr {
		border-bottom: 2px solid #171717;
		&:first-of-type {
			border-top: 2px solid #171717;
		}
	}

	td {
		white-space: pre-wrap;
		font-size: 1.2em;
		padding: 1.2em 1.5em;
		font-weight: 600;
		&:first-of-type,
		&:nth-of-type(3) {
			width: 200px;
			max-width: 100%;
			text-align: center;
			background: #f6f6f6;
		}
	}
}
button.inquiry-go-list-btn {
	width: 138px;
	background: #117ffa;
	color: #fff;
	font-size: 1em;
	display: block;
	margin: 40px auto 80px;
	border-radius: 6px;
	padding: 1em;
}

.ant-tabs-content .max-width-apply.inquiry {
	margin-top: -7.2em !important;
}
