// LandingComponent.jsx
.landing-new-home {
	margin-top: 5em;
	//모바일 매너 라이브러리
	.slick-slider {
		display: block;
	}
	.slick-dots {
		margin-bottom: 4rem !important;
		li {
			margin: 0 !important;
		}
		li button:before {
			font-size: 0.8rem !important;
			color: #fff !important;
		}
	}

	.together-landing-banner-wrap {
		position: relative;
		width: 100%;
		height: 60vh;
		min-height: 480px;

		&.sign-in-login-banner-wrap {
			height: 45vh;
			min-height: 320px;
			.together-landing-banner-title-wrap {
				padding-bottom: 8em;
			}

			@media (max-width: 768px) {
				height: 40vh;
				min-height: 320px;
				display: none;
			}
			@media (max-width: 320px) {
				min-height: 310px;
			}
		}

		> img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: auto;
		}

		.together-landing-banner-title-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding-bottom: 12em;

			@media (max-width: 768px) {
				padding-bottom: 8em;
			}

			h1 {
				font-size: 3em;
				font-weight: bold;
				line-height: 1.5;
				letter-spacing: -1px;
				margin: 0;
				color: white;

				@media (max-width: 768px) {
					font-size: 2.8em;
					white-space: pre;
					text-align: center;
					line-height: 1.3;
					padding-bottom: 1.5rem;
				}
			}

			p {
				font-size: 1.2em;
				line-height: 1.44;
				letter-spacing: -1px;
				margin: 0;
				color: white;
			}

			button.together-landing-banner-signup-button {
				margin-top: 2em;
				display: flex;
				align-items: center;
				gap: 0.3em;
				background-color: #000;
				border-radius: 80px;
				padding: 0.5em 1em 0.5em 1.8em;

				font-size: 1.5em;
				font-weight: 300;
				line-height: 1.44;
				letter-spacing: -1px;
				color: white;

				img {
					width: 1.3em;
					height: 1.3em;
				}
			}
		}
	}

	.together-landing-banner-img-div {
		position: relative;
		margin-top: 6.4em;
		margin-left: auto;
		margin-right: auto;
		padding: 0 3em 12em;
		z-index: 1;
		max-width: 1200px;
		&.signin {
			@media (max-width: 768px) {
				margin-top: 0em;
				padding: 2em 0em 6em;
			}
		}
		> img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
			border-bottom-left-radius: 42px;
			border-bottom-right-radius: 42px;
			@media (max-width: 768px) {
				border-bottom-left-radius: 18px;
				border-bottom-right-radius: 18px;
			}
		}

		.mobile-title-div {
			text-align: center;
			color: #000;
			> h1 {
				font-weight: 600;
				font-size: 2.4rem;
				margin-bottom: 0.5em;
				line-height: 1;
			}
			> p {
				font-weight: 600;
				font-size: 1.2em;
				line-height: 1;
			}
		}
		.mall-login-page-div {
			width: 60%;
			max-width: 680px;
			border-radius: 40px;
			padding: 3.5em 3.2em;
			box-shadow: none;
			border-radius: 16px;
			border: 1px solid #d9d9d9;
			margin-top: 1.5em;
			@media (max-width: 768px) {
				padding: 2em 0 0;
				border: none;
				margin-top: 0;
			}

			.login-div {
				width: 100%;
				margin: auto;
				max-width: 600px;
				// @media (max-width: 768px) {
				// 	width: 100%;
				// }
				@media (max-width: 330px) {
					width: 80%;
				}
			}
		}
	}
}
div.login-password-sub-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	//justify-content: space-between;
	margin: 1.3em auto 0;
	text-align: center;
	p {
		font-size: 1em;
		font-weight: 400;
		letter-spacing: -1.2px;
		&#forget-password {
			color: #979797;
			letter-spacing: -0.9px;
			@media (max-width: 768px) {
				color: #000;
				text-decoration: none !important;
				margin: 0 auto 0;
			}
		}
	}
	&.mobile {
		//display: block;
		@media (max-width: 768px) {
			text-align: center;
		}
	}
}
.together-landing-description-wrap {
	&#first {
		background-color: rgba(17, 127, 250, 0.05);
	}

	&#second {
		background-color: rgba(17, 127, 250, 0.1);
	}

	&#third {
		background-color: rgba(200, 226, 255, 0.1);
	}

	padding: 6em;
	@media (max-width: 768px) {
		padding: 6em 0em;
	}

	.together-landing-description-each {
		margin: auto;
		max-width: 1200px;
		display: flex;
		flex-direction: column;
		gap: 3em;

		h4 {
			margin: 0;
			font-size: 2.2em;
			letter-spacing: -1.5px;
			font-weight: normal;
			line-height: 1.3;
			@media (max-width: 768px) {
				font-size: 1.6em;
				text-align: center !important;
			}
		}

		h3 {
			margin: 0;
			font-size: 3.2em;
			letter-spacing: -1.5px;
			font-weight: bold;
			line-height: 1.3;
			color: #117ffa;
			@media (max-width: 768px) {
				padding-top: 0.5rem;
				font-size: 2.4em;
				text-align: center !important;
			}
		}
	}
}

.landing-image-group-wrap {
	display: flex;
	align-items: center;
	gap: 8%;
	row-gap: 4em;
	@media (max-width: 768px) {
		flex-direction: column;
		padding: 0 3em;
	}

	.landing-image-shadow-wrap {
		width: 31%;
		border-radius: 24px;
		box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
		background-color: #fff;
		position: relative;
		overflow: hidden;
		@media (max-width: 768px) {
			width: 85%;
			border-radius: 30px;
		}

		.image-div {
			position: relative;
			overflow: hidden;

			&:after {
				content: "";
				display: block;
				padding-bottom: 60%;
			}
			&#full {
				&:after {
					padding-bottom: 80%;
				}
			}
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		h5 {
			margin: 0;
			width: 100%;
			font-size: 1.7em;
			font-weight: bold;
			letter-spacing: -1px;
			line-height: 1.47;
			text-align: center;
			padding: 1em 1.5em 0;
		}
		h6 {
			margin: 0;
			width: 100%;
			font-size: 1.5em;
			font-weight: 500;
			letter-spacing: -1px;
			line-height: 1.47;
			text-align: center;
			padding: 1em 1.5em 1.5em;
		}
		p {
			margin: 0;
			width: 100%;
			font-size: 1.2em;
			font-weight: 400;
			letter-spacing: -1px;
			line-height: 1.47;
			text-align: center;
			color: #2a2a2a;
			padding: 1em 1.5em 1.5em;
			white-space: pre-wrap;
		}
	}
}

.landing-description-footer-font {
	font-size: 1.6em;
	font-weight: normal;
	letter-spacing: -2px;
	color: #2a2a2a;
	white-space: pre;
	text-align: center;

	padding-top: 1em;
	&.pc {
		display: block;

		@media (max-width: 768px) {
			display: none;
		}
	}
	&.mobile {
		display: none;

		@media (max-width: 768px) {
			font-size: 1.4em;
			display: block;
		}
	}
}

.signup-login-footer-text-mobile {
	display: none;
	box-sizing: border-box;
	width: 100%;
	padding: 1.6rem;
	@media (max-width: 768px) {
		justify-content: left;
		display: flex;
		border-top: 11px solid #f6f6f6;
	}
	P {
		color: #2a2a2a;
		letter-spacing: -1px;
		white-space: pre-wrap;
		text-align: left;
		> span {
			display: block;
			padding-top: 1em;
			> button {
				color: #117ffa;
				text-decoration: underline;
			}
		}
	}
}
